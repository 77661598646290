// const Mock =require('mockjs');//获取mockjs插件.这种方式也可以
import Mock from 'mockjs'
const Random = Mock.Random
const produceData = function (opt) {
  console.log('opt:', opt) // 这里注意：不能用加号代替逗号, 即不能写成：console.log('opt:'+opt);
  const articles = []
  for (let i = 0; i < 10; i++) {
    const newArticleObject = {
      name: Random.cname(), // 随机生成一个中文名
      email: Random.email(), // 随机生成一个电子邮件
      date: Random.date() + ' ' + Random.time() // 随机生成日期和时间
    }
    articles.push(newArticleObject)
  }
  return {
    data: articles
  }
}
Mock.mock('/user', /post|get/i, produceData) // 当post或get请求到/user路由时，mock会拦截请求并返回
// Mock.mock('/user','post',produceData); // 这种方式就只能用post请求,注意post要加引号
