<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted () {
    let isPc = true
    if (this._isMobile()) {
      isPc = false
    } else {
      isPc = true
    }
    this.$store.commit('setIsPc', isPc)
  },
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.el-tooltip__popper.is-dark.tip-class {
  width: 13% !important;
}
#app {
  font-family: PingFangSC-Semibold, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  a {
    text-decoration: none;
  }
  .active {
    background-color: #019efa;
  }
  .el-dialog__body {
    border-top: 1px solid #f0f0f0;
  }
  .el-dialog__headerbtn {
    top: 9px;
  }
  .is-active {
    color: black !important;
  }
  .nav-tabs-all{
    .is-active{
      color: white !important;
      max-width: 200px;
      height:100%
    }
  }
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs__active-bar {
    background-color: #014dfa;
  }
   .nav-tabs-all .el-tabs__active-bar {
    background-color: white;
  }
  .el-tabs__nav{
      display:flex;
      width:100%!important;
      height:72px;
      word-wrap:break-word!important;
  }
  @media screen and (min-width: 768px) {
  // @media screen and (min-width: 500px) {
    .container {
      position: relative;
      min-height: calc(100vh - 306px);
    }
    .el-tabs__nav-wrap::after{
      background-color: transparent;
    }
    // pc端tab样式
    .el-tabs__item {
      white-space: break-spaces;
      padding: 0;
      width: 25%;
      color: #999;
      text-align: center;
      height: 72px;
      line-height:inherit;
      word-wrap:break-word!important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
    }
    .el-tabs__nav{
        display:flex;
        width:100%!important;
        height:72px;
        word-wrap:break-word!important;
    }
    // 隐藏移动端菜单
    .menu {
      display: none;
    }
    // 对话框pc端居中
    .el-dialog {
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 500px) {
    .home,.financing-service,.incubation-service{
      width: 100vw;
      overflow-x: hidden;
    }
    .main{
      // width: calc( 100% - 32px );
      width: 100vw;
      padding-right: 16px;
      padding-left: 16px;
      box-sizing: border-box;
    }
    // .incubation-content .content-box{
    //   width:90vw
    // }
    .news-center{
      width: 100vw;
      box-sizing: border-box;
      overflow-x: hidden;
      // width: calc(100% - 32px);
      padding-right: 1rem;
      padding-left: 1rem;
      padding-bottom: 2rem;
    }
    .case-box{
      .case{
        width: 100%;
        width: calc(100% - 32px);
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
    .sponsor{
      width: 100%;
      width: calc(100% - 32px);
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .service{
      width: calc(100% - 32px);
      padding-right: 1rem;
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
    .incubation-alls {
      display: block;
      .title{
        font-weight: 500;
        font-size: 0.8rem;
        word-break: break-all;
      }
    }
    .container {
      position: relative;
      min-height: calc(100vh - 327px);
    }
    // 移动端tab样式
    .el-tabs__nav{
      white-space:normal !important;
    }
    .el-tabs__item {
      margin-top: 1rem;
      padding: 0;
      width: 25vw;
      color: #b4b5b7;
      font-size: 0.9rem;
      text-align: center;
      line-height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .container {
      /* padding-top: 60px; */
      .Header-item{
        position:static
      }
    }
    // 隐藏pc端导航栏
    .header-all{
      background: white;
      width: 100%;
    }
    .header {
      justify-content: space-between;
      height: 60px;
      background: white;
      width:100%;
      padding-right: 16px;
      padding-left: 16px;
      .logo {
        display: none;
      }
      .logo_mob {
        display: block;
      }
      .nav-web {
        display: none;
      }
      .menu-btn {
        display: block;
      }
    }
    // 移动端对话框样式及排版
    .el-dialog {
      width: 80%;
    }
    .el-dialog__body {
      padding-top: 0;
      border-top: 0;
    }
    .dialog {
      .dialog-content-web {
        display: none;
        flex-direction: column;
        align-items: center;
        .detail {
          padding-left: 0;
          padding-top: 30px;
        }
        .photo {
          width: 60%;
        }
      }
      .dialog-content-mob {
        display: block;
        .dialog-mob-home {
          display: flex;
          .home-photo {
            width: 80px;
            height: 80px;
            margin-right: 8px;
          }
        }
      }
    }
    // copyright移动端排版
    .copyright {
      padding: 24px 16px 0 16px;
      .top {
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        .title {
          font-size: 12px;
        }
        .synopsis {
          display: flex;
          flex-direction: column-reverse;
          margin-top: 20px;
          margin-right: 0;
          .logo {
            margin: 10px 0;
            width: 24px;
          }
        }
        .contact-way {
          margin-left: 0;
        }
      }
      .bottom {
        border: none;
        margin-top: 0;
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
        color: #999;
        width: 100%;
        span {
          margin: 0;
        }
      }
    }
    // 移动端下纵向排列 子元素占满屏幕
    // 首页排版
    .content {
      padding-top: 0;
      // 标题
      .home-title {
        font-size: 15px;
        color: #222;
        border-left: 2px solid #17cad0;
        margin-bottom: 3vh;
        .more {
          font-size: 13px;
          width: 3.5rem !important;
        }
      }
      .culture-item{
        margin-top: 0px;
      }
      // 公司文化介绍
      .culture {
        display: flex;
        padding-top: 2vh;
        justify-content: space-between;
        .culture-img {
          width: 30%;
          max-height: 22vw;
        }
        .img-item{
          width: 33.3%;
          .title{
            line-height: 10px;
            font-size: 10px;
            margin-bottom:2px
          }
          .center{
            line-height: 10px;
            font-size: 10px;
          }
          img{
            width: 100%;
          }
        }
      }
      // 上市前服务项
      .service-content {
        .service-item {
          width: 25%;
          margin-bottom: 1vh;
          .service-img-round {
            width: 70%;
          }
          .incubation-all {
            display: none;
          }
          .incubation-img-mob {
            display: block;
            object-fit: cover;
            height: 4.5rem;
            border-radius: 0.5rem;
          }
          .service-text {
            font-size: 11px;
            color: #999;
            text-align: center;
          }
        }
      }
      // 上市保荐人联盟
      .sponsor-item {
        .sponsor-img-web {
          display: none;
        }
        .sponsor-img-mob {
          display: block;
          width: 100%;
          margin-bottom: 2vh;
          max-height: 24vw;
        }
      }
      // 新闻中心
      .news-center {
        .home-news-content-web {
          display: none;
        }
        .home-news-content-mob {
          display: block;
          .home-news-img-mob {
            width: 100%;
            // height: 16rem;
          }
          .news-title {
            font-size: 15px;
            color: #222;
            font-weight: bold;
            // padding: 1vh 0;
            margin: 1vh 0;
          }
          .news-text {
            font-size: 11px;
            color: #999;
          }
        }
      }
      // 案例
      .case-box {
        background-image: none;
        .case-content {
          flex-wrap: wrap;
          .case-item {
            display: none;
          }
          .case-item-mob {
            width: 32%;
            display: block;
            margin-bottom: 16px;
            .case-img-mob {
              width: 100%;
              height: 67px;
              display: block;
            }
            .case-title {
              color: #002537;
              font-size: 0.6rem;
              min-height: 32px;
              font-weight: bold;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #dcdcdc;
              word-break:break-all;
            }
          }
        }
      }
    }

    // 新闻中心排版
    .news-center {
      .news-content-web {
        display: none;
      }
      .news-content-mob {
        display: block;
      }
    }
    // 上市孵化服务
    .incubation-content {
      .content-box {
        width: 100% !important;
        .content-title {
          border-left: 2px solid #17cad0;
          font-size: 15px;
          color: #222;
          margin-left: 1rem;
        }
        .main {
          padding: 0;
        }
        .item {
          width: 25%;
          margin-bottom: 0;
          .index {
            flex-direction: column;
            align-items: center;
            .logo-img {
              width: 50px;
              height: 50px;
            }
            .item-title {
              text-align: center;
              color: #999;
              font-size: 11px;
              margin-bottom: 0;
              margin-top: 1vh;
            }
            .item-text {
              display: none;
            }
          }
        }
        .item-compliance {
          margin-bottom: 1vh;
          .item-img {
            border-radius: 0.5rem;
            height: 4.5rem;
            object-fit: cover;

          }
          .item-compliance-text{
            font-weight: 500;
            font-size: 0.8rem;
            word-break: break-all;
          }
        }
        .brand {
          justify-content: center;
          .brand-incubation {
            width: 33%;
            justify-content: center;
            margin-bottom: 3vh;
            .brand-img {
              width: 50px;
              height: 50px;
            }
            .brand-title {
              font-size: 11px;
              color: #999;
              text-align: center;
            }
          }
        }
        /*新增认缴实缴*/
        .capital-box{
          flex-direction: column;
          padding: 0 1rem;
          .h5_none{
            display: none;
          }
          .capital-list{
            padding: 0;
            max-width: 100%;
            .capital-info{
              flex-direction: column;
              .capital-text{
                margin: 1rem 0 1.5rem;
                font-size: 0.8rem;
              }
              .capital-img{
                order: -1;
                .web_none{
                  display: flex;
                  max-width: 100%;
                  height: 7.5rem;
                }
              }
            }
          }
        }
        .content-child-title{
          font-size: 0.95rem;
          color: #222;
          margin-left: 1rem;
        }
        .capital-item{
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 1rem 1rem 1.5rem 1rem;
          .item-custom{
            max-width: 50%;
            width: auto;
            margin-bottom: 1rem;
            img{
              width: 6.5rem;
            }
            .custom-info-box{
              padding: 1.5rem 0 0 3rem;
              .custom-title{
                text-indent: 0.5rem;
                font-size: 1.1rem;
                color: #000;
                font-weight: 600;
                padding-bottom: 0.5rem;
              }
              .custom-info{
                font-size: 0.8rem;
                max-width: 7rem;
              }
            }
          }
        }
      }
      .product-sale {
        padding: 0 5vw;
        padding-bottom: 24px;
        flex-direction: column;
        .product-item {
          width: 100%;
          margin-top: 24px;
          .sale-title {
            font-size: 17px;
            color: #222;
          }
        }
      }
    }
    // 上市融资服务
    .financing-service {
      .financing-title {
        font-size: 15px;
        color: #222;
      }
      .img-mob {
        width: 100%;
        display: block;
      }
      .img-web {
        display: none;
      }
      .box-img {
        width: 110px;
      }
      // 境外上市途径
      .abroad-way {
        display: none;
      }
      // 境外交易所
      .abroad {
        display: none;
      }

      // 知识产权证券化
      .part-knowledge {
        // height: 275px;
        .knowledge-title {
          font-size: 15px;
          color: #fff;
        }
        .knowledge-text {
          width: 72%;
          font-size: 11px;
        }
        .content-footer {
          // margin-top: 14px;
          margin-top: 5vh;
          margin-left: -5vw;
          width: 100vw;
          // width: 92%;
          display: flex;
          justify-content: space-around;
          // justify-content: space-between;
          flex-wrap: wrap;
          .way {
            min-width: auto;
            max-width: none;
            width: 46%;
            height: auto;
            margin: 0;
            margin-bottom: 16px;
            padding: 4px 0 7px 7px;
            background-color: #2b6378;
            color: white;
            border-radius: 4px;
            border: 0;
            font-weight: bold;
            font-size: 11px;
            .way-case {
              margin-top: 2px;
              font-size: 8px;
              color: #999;
            }
          }
        }
      }
    }
    // bp辅导
    .bp-service{
      width: 100%;
      overflow: hidden;
      .h5_none{
        display: none;
      }
      .bp-tip-web{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: 1.9rem 0.6rem 0;
        img{
          width: 100%;
          max-width: 24rem;
          object-fit: cover;
        }
      }
      .bp-content{
        .content-box{
          padding-top: 1rem;
        }
        .content-two{
          padding-top: 2.5rem;
          .carousel-server{
            display: block;
            .swiper{
              height:14.5rem;
              /deep/.swiper-pagination-bullet-active{
                background: #fff;
              }
              .swiper-slide{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                  height: 14.5rem;
                }
              }
            }
          }
        }
        .content-title{
          border-left: 2px solid #17cad0;
          font-size: 1rem;
          color: #222;
          margin-left: 1rem;
          margin-bottom: 2vh;
          padding-left: 1vw;
          padding-right: 0.5rem;
        }
        .main{
          .item{
            width: 25%;
            margin:0 !important;
            .item-box{
              flex-direction: column;
              // justify-content: center;
              align-items: center;
              img{
                margin: 0;
                width: 4rem;
                height: 4rem;
              }
              div{
                width: auto;
                .item-title{
                  color: #14BDC3;
                  font-size: 0.9rem;
                  margin: 0;
                }
                .item-text{
                  display: none;
                }
              }
            }
          }
        }
        .content_organ_web{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 1.5rem 1rem 2rem;
          h3{
            margin-bottom: 1rem;
            color: #222;
            font-size: 1rem;
          }
          img{
            max-width: 24rem;
            width: 100%;
            object-fit: fill;
          }
        }
        .content-custom{
          display: block;
          h3{
            margin-bottom: 1rem;
            color: #222;
            font-size: 1rem;
            margin-left: 1rem;
          }
          .item-custom-box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 1rem;
            // padding: 1rem 0 1rem 1rem;
          }
          .item-custom{
              max-width: 50%;
              position: relative;
              margin-bottom: 1rem;
              img{
                position: absolute;
                top: 0;
                left: 0;
                width: 6.5rem;
              }
              .custom-info-box{
                padding: 1.5rem 0 0 3rem;
                z-index: 10;
                 .custom-title{
                  font-size: 1.1rem;
                  color: #000;
                  font-weight: 600;
                  font-style:oblique;
                  margin-bottom: 0.5rem;
                  text-indent: 1rem;
                }
                .custom-info{
                  color: #888;
                  font-size: 0.8rem;
                  max-width: 7rem;
                }
              }
            }
        }
        .content-box-web{
          display: block;
          h3{
            margin: 1rem 0;
            color: #222;
            font-size: 1rem;
            text-align: center;
          }
          .carousel-case{
            margin: 0 5.79rem;
            .swiper{
              height:11rem;
              border-radius: 0.5rem;
              /deep/.swiper-pagination-bullet{
                background: #fff;
              }
              .swiper-slide{
                border-radius: 0.5rem;
                img{
                  width: 100%;
                  height:11rem;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    .financing-service {
      .main {
        .abroad {
          .opcity-box {
            width: 40vw;
            height: 17vh;
            bottom: 9vh;
            left: 3vw;
          }
          .box {
            // width: 5.5vw;
            // height: 14vh;
            // bottom: 8vh;
            // left: 3vw;
            width: 4vw;
            height: 6vh;
            bottom: 7vh;
            left: 2vw;
          }
          .center {
            // left: 11.5vw;
            left: 7.8vw;
          }
          .right {
            // left: 24vw;
            left:16.2vw;
          }
        }
      }
    }
  }
   @media screen and (min-width: 500px) and (max-width: 1300px) {
     .header-all{
       width: 90vw;
     }
     .container .culture-item .culture{
       width: 90vw;
     }
     .container .culture-item{
       margin-top: -10vw;
     }
     .content .service,.content .sponsor,.home .news-center,.content .case-box .case,.copyright .top,.copyright .bottom{
      width: 90vw;
      padding-bottom: 3vw;
      padding-top: 3vw;
     }
     .home .news-center{
       padding-bottom: 1vw;
     }
     .main{
       width: 90vw;
     }
     .main .part .abroad .abroad-img{
      width: 80vw;
      height: 26vw;
     }
     .main .part .abroad .abroad-img-right{
      width: 70vw;
      height: 20vw;
     }
     .main .territory .box .box-img{
       width: 100%;
     }
     .copyright .bottom{
       margin-top: 6vw;
     }
     .incubation-content .content-box{
       width:90vw
     }
     .news-content-web{
       width:90vw
     }
     .main .part .abroad-way .step .step-box .step-img{
       width: 90px;
       height: 90px;
     }
     .main .part .abroad-way .step .step-box .step-img::after{
       width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #fff;
      position: relative;
      top: 15px;
      left: 15px;
      line-height: 60px;
      text-align: center;
     }
   }
}
</style>
<style lang="less">
/deep/ .el-tabs__nav{
    white-space: break-spaces;
    word-wrap:break-word!important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.el-tabs__nav{
    white-space: break-spaces;
    word-wrap:break-word!important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
