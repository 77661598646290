<!-- 弹出框 -->
<template>
  <el-dialog
    :visible="dialogVisible"
    :before-close="handleCloseDialog"
    class="dialog"
  >
    <!-- web端弹窗 -->
    <div class="dialog-content-web">
      <img :src="url" class="photo" alt="" />
      <div class="detail">
        <p class="dialog-title" v-if="title">{{ title }}</p>
        <p
          class="introduction"
          v-for="(item, index) in introduction"
          :key="index"
        >
          {{ item }}
        </p>
        <p class="text">{{ text }}</p>
      </div>
    </div>
    <!-- 移动端弹窗 -->
    <div class="dialog-content-mob">
      <!-- 移动端首页弹窗 -->
      <div class="dialog-mob-home" v-if="!token">
        <img :src="url" class="home-photo" alt="" />
        <div class="describe">
          <p class="dialog-title-mob" v-if="title">{{ title }}</p>
          <p
            class="introduction"
            v-for="(item, index) in introduction"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <div class="detail">
        <p class="text">{{ text }}</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'InfoDialog',
  props: [
    'dialogVisible', 'title', 'introduction', 'url', 'text', 'token'
  ],
  data () {
    return {}
  },
  methods: {
    handleCloseDialog () {
      this.$emit('handleCloseDialog')
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {}
}
</script>
<style scoped lang='less'>
.dialog {
  .dialog-title {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 1vh;
  }
  .dialog-title-mob {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 1vh;
    color: #222;
  }
  .introduction {
    color: #999;
  }
  .text {
    margin-top: 2vh;
    padding-top: 2vh;
    border-top: 1px solid #f0f0f0;
    line-height: 20px;
    color: #222;
  }
  .dialog-content-web {
    display: flex;
    .photo {
      height: 216px;
      width: 216px;
    }
    .detail {
      flex: 1;
      padding-left: 1vw;
    }
  }
  .dialog-content-mob {
    display: none;
  }
}
</style>
