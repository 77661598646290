<template>
<div class='header-all'>
  <div class="header">
    <div class="logoimg">
    <img class="logo_mob" src="../../assets/home_head_logo_mob.png" />
    <img class="logo" src="../../assets/home_head_logo.png" />
    </div>
    <div class="nav-web">
      <div class="nav-tabs-all">
      <el-tabs v-model="navName" @tab-click="goRouterbar">
        <el-tab-pane style="white-space: nowrap" v-bind:label="$t('lang.navbar.HOME')" name="0" :index="0" path="/"/>
        <el-tab-pane v-bind:label="$t('lang.navbar.LISTING_FINANCING_SERVICE')" name="1" :index="1" path="financing_service"/>
        <el-tab-pane v-bind:label="$t('lang.navbar.PREIPO_INCUBATION_SERVICE')" name="2" :index="2" path="incubation_service"/>
        <el-tab-pane v-bind:label="$t('lang.navbar.NEWS_CENTER')" name="3" :index="3" path="news_center"/>
      </el-tabs>
      </div>
      <ul class="language">
        <li
          :class="{ current: getLangIndex==0 }"
          @click="handleChangeLange(0)"
        >
          <div class="changeicon" v-if="getLangIndex==0"></div>
          <div>{{ $t("lang.navbar.CHINESE") }}</div>
        </li>
        <li
          :class="{ current: getLangIndex==1 }"
          @click="handleChangeLange(1)"
        >
          <div class="changeicon" v-if="getLangIndex==1"></div>
          <div>{{ $t("lang.navbar.ENGLISH") }}</div>
        </li>
        <li
          :class="{ current: getLangIndex==2 }"
          @click="handleChangeLange(2)"
        >
          <div class="changeicon" v-if="getLangIndex==2"></div>
          <div>{{ $t("lang.navbar.PORTUGAES") }}</div>
        </li>
      </ul>
    </div>
    <img :src="url" alt="" class="menu-btn" @click="handleChangeFlag()" />
    <div class="menu" v-show="flag">
      <ul class="nav_mob">
        <li :class="{ hit: getNavIndex==0 }" @click="handleHit(0)">
          <router-link to="/">{{
            $t("lang.navbar.HOME")
          }}</router-link>
        </li>
        <li :class="{ hit: getNavIndex==1 }" @click="handleHit(1)">
          <router-link
            to="/financing_service"
            >{{ $t("lang.navbar.LISTING_FINANCING_SERVICE") }}</router-link
          >
        </li>
        <li :class="{ hit: getNavIndex==2 }" @click="handleHit(2)">
          <router-link
            to="/incubation_service"
            >{{ $t("lang.navbar.PREIPO_INCUBATION_SERVICE") }}</router-link
          >
        </li>
        <li :class="{ hit: getNavIndex==3 }" @click="handleHit(3)">
          <router-link
            to="/news_center"
            style="border-bottom: none"
            >{{ $t("lang.navbar.NEWS_CENTER") }}</router-link
          >
        </li>
      </ul>
      <ul class="language">
        <li
          :class="{ hit: getLangIndex==0 }"
          @click="handleChangeLange(0)"
        >
          {{ $t("lang.navbar.CHINESE") }}
        </li>
        <li
          :class="{ hit: getLangIndex==1 }"
          @click="handleChangeLange(1)"
        >
          {{ $t("lang.navbar.ENGLISH") }}
        </li>
        <li :class="{ hit: getLangIndex==2 }"
        @click="handleChangeLange(2)">
          {{ $t("lang.navbar.PORTUGAES") }}
        </li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters(['getLangSelect', 'getLangIndex', 'getNavIndex'])
  },
  data () {
    return {
      flag: false,
      url: require('../../assets/home_head_icon_menu.png'),
      navName: '0'
    }
  },
  // 方法集合
  methods: {
    // 前往不同tab
    goRouterbar (e) {
      this.navName = e.name
      this.$store.dispatch('setNavIndex', e.$attrs.index)
      this.$router.push(e.$attrs.path)
    },
    handleChangeFlag () {
      this.flag = !this.flag
      this.url = this.flag ? require('../../assets/home_head_icon_menu_close.png') : require('../../assets/home_head_icon_menu.png')
    },
    handleChangeLange (index) {
      this.$store.dispatch('setLangIndex', index)
      this.$i18n.locale = this.getLangSelect
      this.$emit('getnowtype')
    },
    handleHit (index) {
      this.$store.dispatch('setNavIndex', index)
    }
  },
  created () {
    this.navName = this.getNavIndex + ''
  }
}
</script>

<style scoped lang="less">
.header-all{
  display:flex;
  justify-content: center;
  width: 1200px;
  max-width: 1600px;
  margin: auto;
}
.header {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a8969d;
  z-index: 666;
  width: 1200px;
  max-width: 1600px;
  .logo_mob {
    display: none;
  }
  .nav-web {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    a {
      text-align: center;
      color: rgba(255,255,255,0.5);
      height: 72px;
      display: block;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .hitweb {
      color: white;
      border-bottom: 1px solid #fff;
    }
    .current {
      /* list-style-type: disc; */
      color: white;
    }
    a:hover {
      color: #fff;
    }
    .language {
      width: 20%;
      list-style: none;
      text-align: center;
      color: rgba(255,255,255,0.5);
      /* li{
        color:white;
        opacity:0.5
      } */
      li{
        padding-left: 20px;
        display: flex;
        // justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      li:hover {
        cursor: pointer;
        color: white;
      }
    }
  }
  .menu {
    line-height: 50px;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: auto;
    background: white;
    z-index: 111;
    .nav_mob {
      padding: 0 15vw;
      a {
        display: block;
        color:rgba(0,0,0,0.5);
        border-bottom: 1px solid #efefef;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 12px;
      }
    }
    .hit {
      color: black;
      list-style: disc;
      a {
        color: black;
      }
    }
    .language {
      padding: 0 15vw;
      display: flex;
      justify-content: space-between;
      background: #dfdfdf;
      text-align: center;
      color: rgba(0,0,0,0.5);
      height: 35px;
      border-radius: 0 0 10px 10px;
    }
    li {
      list-style: none;
      line-height: normal;
      height: 2.2rem;
      line-height: 2.2rem;
      font-size: 12px;
    }
  }
  .menu-btn {
    display: none;
  }
}
.header{
  .logoimg{
    .logo{
      height:72px
    }
  }
}
.changeicon{
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  margin-right: 5px;
}
.nav-tabs-all{
  width:100%;
  .is-active{
    color: white !important;
  }
  /deep/.el-tabs__nav-wrap{
    height:72px!important;
  }
}
</style>
<style lang="less">
.nav-tabs-all /deep/.el-tabs__nav-wrap{
    height:72px!important;
  }
.nav-tabs-all /deep/ .el-tabs__item{
    width:20%
}
/* .nav-tabs-all /deep/ .el-tabs__nav{
    width:100%!important;
    max-width: 200px;
    word-wrap:break-word!important;
} */
/deep/ .el-tabs__nav-scroll{
    word-wrap:break-word!important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
/deep/ .el-tabs__nav{
    word-wrap:break-word!important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
