<!--  -->
<template>
  <div class="app-dashboard">
    <el-row>
      <!-- 左边菜单项开始 -->
      <el-col :span="4" style="height: 100vh">
         <el-menu
          style="height: 100%"
          active-text-color="#409eff!important"
          text-color="#fff"
          background-color="#1f2d3d"
          :default-active="defaultActive"
          router
        >
          <img
            src="../assets/home_head_logo.png"
            width="100%"
            height="100px"
            alt=""
          />
          <!-- 数据管理开始 -->
          <el-submenu index="1">
            <template slot="title">
              <span>新闻管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="news" :class = "menubg?'menubg1':'menubg2'">中文资讯</el-menu-item>
              <el-menu-item index="news" :class = "menubg?'menubg1':'menubg2'" :route="{ path: 'news', query: { lang: 'EN' } }">English News</el-menu-item>
              <el-menu-item index="news" :class = "menubg?'menubg1':'menubg2'" :route="{ path: 'news', query: { lang: 'PT' } }">Portugaes News</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 数据管理结束 -->
        </el-menu>
      </el-col>
      <!-- 左边菜单项结束 -->
      <!-- 右边内容项开始 -->
      <el-col :span="20">
        <router-view></router-view>
      </el-col>
      <!-- 右边内容项结束 -->
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isCollapse: false,
      menubg: 'zh'
    }
  },
  computed: {
    defaultActive: function () {
      return this.$route.path.split('/')[1]
    }
  },
  methods: {},
  created () {},
  mounted () {
    if (this.$route.query === 'EN') {
      this.menubg = 'en'
    } else if (this.$route.query === 'PT') {
      this.menubg = 'pt'
    } else {
      this.menubg = 'zh'
    }
  }
}
</script>
<style scoped lang='less'>
.el-menu-item {
  min-width: 0;
}
</style>
<style lang='less'>
.el-menu-item .is-active .menubg1{
  background:red
}
/deep/ .menubg2{
  background:yellow
}
/deep/ .menubg1{
  background:yellow
}
.el-menu-item.is-active {
  background-color: transparent !important;
}
</style>
