import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const langList = ['zh-CN', 'en-US', 'pt-PT']

const store = new Vuex.Store({
  state: {
    isPc: true, // 判断所在平台pc/H5 默认pc
    navIndex: Number(localStorage.getItem('navIndex')) || 0, // 导航的下标
    langIndex: Number(localStorage.getItem('lang')) || 0, // 选择语言的下标
    langSelect: langList[Number(localStorage.getItem('lang')) || 0] // 选择的语言
  },
  getters: {
    getIsPc: state => state.isPc,
    getNavIndex: state => state.navIndex,
    getLangIndex: state => state.langIndex,
    getLangSelect: state => state.langSelect
  },
  mutations: {
    setIsPc (state, data) {
      state.isPc = data
    },
    setNavIndex (state, data) {
      state.navIndex = data
    },
    setLangIndex (state, data) {
      state.langSelect = langList[data]
      state.langIndex = data
    }
  },
  actions: {
    setNavIndex ({ commit }, data) {
      commit('setNavIndex', data)
      localStorage.setItem('navIndex', data)
    },
    setLangIndex ({ commit }, data) {
      commit('setLangIndex', data)
      localStorage.setItem('lang', data)
    }
  }
})

export default store
