// 中文语言包
export const lang = {
  // nav
  navbar: {
    HOME: '首页',
    LISTING_FINANCING_SERVICE: '上市融资服务',
    PREIPO_INCUBATION_SERVICE: '上市前孵化服务',
    NEWS_CENTER: '新闻中心',
    CHINESE: '简体中文',
    ENGLISH: 'English',
    PORTUGAES: 'Portugaes'
  },
  value: {
    PHILOSOPHY: '我们的理念',
    PHILOSOPHYCENTER: '感恩、利他、尊重、理解、包容',
    OURVALUE: '我们的价值观',
    OURVALUECENTER: '同心、同德、同行、同享',
    VISION: '我们的愿景',
    VISIONCENTER: '让大众创业更加简单;',
    VISIONCENTER2: '让有德的人有钱，有钱的人有德！'
  },
  financing: {
    SERVICES: '中国企业的境外上市融资服务',
    TRADEABROAD: '最有效的境外交易途径',
    VATSPECIAL: '境外交易所',
    MAINLAND: '内地交易所',
    VISION: '我们的愿景',
    INTELLECTUAL: '知识产权证券化',
    MORE: '更多'
  },
  service: {
    TITLE: '企业上市前孵化服务',
    listtitle1: '商业模式企划书',
    listtitle2: '品牌新趋势',
    listtitle3: '营销新方式',
    listtitle4: '营销新工具',
    listtitle5: '品牌孵化',
    listtitle6: '合规管理',
    listtitle7: '私募股权投资服务',
    MORE: '更多'
  },
  atrocinador: {
    TITLE: '上市保荐人联盟',
    MORE: '更多'
  },
  journalism: {
    TITLE: '新闻中心',
    details: '查看详情',
    MORE: '更多'
  },
  case: {
    TITLE: '企服案例',
    case1title: '创新工场',
    case1: '由李开复博士创办，致力于早期阶段投资，并提供全方位创业培育的投资机构与创业平台，旨在培育创新人才和新一代高科技企业。',
    case2title: '深圳市科技金融促进会',
    case2: '由深圳科技界、金融界企业单位自愿组成，秉持非盈利性、互帮互助为原则的经济类社团组织。',
    case3title: '木林森',
    case3: '集LED封装与LED应用产品为一体的综合性光电企业，拥有高效的生产、研发和检测设备及先进的生产管理技术，已成为全球知名LED生产企业。',
    case4title: '清科',
    case4: '是中国领先的创业与投资综合服务商及知名投资机构，旗下业务包含：清科创业研究院、沙丘学堂、项目工场。',
    case5title: '师董会',
    case5: '专注于全球商业智慧共享及实战互助的智联网公司，以信息服务、知识共享服务、企业生态服务、科技孵化器、教育培训服务、投融资服务、产业中国项目等为主营的数字化国家级高新技术企业。',
    case6title: '小鹅通',
    case6: '以知识产品与用户服务为核心的技术服务商，创始至今已服务逾百万家客户，产品包含知识店铺、企业直播、企业培训等，为企业提供线上经营、商业变现、私域运营等一站式服务。'
  },
  develop: {
    TITLE: '企业发展的资本路径',
    img: require('../assets/service_way_img_1.png'),
    img1: require('../assets/service_way_img_1en.png'),
    img2: require('../assets/service_way_img_1pt.png')
  },
  approach: {
    TITLE: '最有效的境外上市途径',
    img: require('../assets/service_way_img_1.png'),
    img1: require('../assets/service_way_img_1en.png'),
    img2: require('../assets/service_way_img_1pt.png')
  },
  Foreign: {
    TITLE: '境外交易所',
    profit: '利润要求：由保荐人决定',
    market: '市值要求：由保荐人决定',
    other: '成立时间：半年以上',
    profit1: '利润要求：由保荐人决定',
    market1: '市值要求：由保荐人决定',
    other1: '资产要求：IFRS审计财报',
    profit2: '利润要求：由保荐人决定',
    market2: '市值要求：最低5百万欧',
    other2: '资产要求：IFRS审计财报',
    profit3: '利润要求：750万美元',
    market3: '市值要求：5000万美元',
    profit4: '市值要求：7500万美元',
    market4: '资产要求：7500万美元',
    profit5: '利润标准：最近三年均为',
    market5: '正且累计≥1100万美元，',
    other5: '最近两年每年≥220万美元',
    profit6: '注册资本：大于70万英镑',
    market6: '市值要求：2500万英镑',
    profit7: '现金流要求：3千万港元',
    market7: '市值要求：1.5亿港元',
    profit8: '利润标准：最近三年均为',
    market8: '正且累计≥5000万港元',
    other8: '市值要求：5亿港元'
  },
  // 证券资产化
  security: {
    TITLE: '资产证券化',
    center: '资产证券化是指以基础资产未来所产生的现金流为偿付支持，通过结构化设计进行信用增级，在此基础上发行资产支持证券（Asset-backed Securities，ABS）的过程。',
    entity: '实体资产',
    entitycenter: '房产地产、机器设备等',
    number: '数字资产',
    numbercenter: '数据 系统等',
    intellectual: '知识产权资产',
    intellectualcenter: '发明专利 外观专利等',
    property: '财产资产',
    propertycenter: '应收账款'
  },
  domestic: {
    TITLE: '境内交易所',
    SHENGZHEN: '※主板：<br/>最近三年经营活动现金流量净额累计＞5000万元，或者最近三年营业收入累计＞3亿元；<br/>最近一期末无形资产占净资产的比例不高于20%；<br/>发行前股本总额≥3000万元，发行后股本总额≥5000万元；<br/>发行后股本总额≤4亿股，公开发行比例须≥25%；<br/>发行后股本总额>4亿股，公开发行比例须≥10%。<br/>※创业板：<br/>发行后股本总额≥3000万元；<br/>发行后总股本≤4亿股，公开发行比例须≥25%；<br/>发行后总股本＞4亿股，公开发行比例须≥10%。',
    SHANGHAI: '※主板：<br/>最近三年经营活动现金流量净额累计＞5000万元，或者最近三年营业收入累计＞3亿元；<br/>最近一期末无形资产占净资产的比例不高于20%；<br/>发行前股本总额≥3000万元，发行后股本总额≥5000万元；<br/>发行后股本总额≤4亿股，公开发行比例须≥25%；<br/>发行后股本总额>4亿股，公开发行比例须≥10%。<br/>※科创板：<br/>最近三年研发投入占营业收入比例5%以上，或者最近三年研发投入金额累计6000万元以上；<br/>发行后股本总额≥3000万元；<br/>发行后股本总额≤4亿元，公开发行比例须≥25%；<br/>发行后股本总额＞4亿元，公开发行比例须≥10%。',
    BEIJIN: '※标准一：<br/>市值≥2亿元；<br/>最近两年净利润均不低于1500万元且加权平均净资产收益率平均不低于8%，或者最近一年净利润不低于2500万元且加权平均净资产收益率平均不低于8%。<br/>※标准二：<br/>市值≥4亿元；<br/>最近两年营业收入平均不低于1亿元，且最近一年营业收入增长率不低于30%，最近一年经营活动产生的现金流量净额为正。<br/>※标准三：<br/>市值≥8亿元；<br/>最近一年营业收入不低于2亿元，最近两年研发投入合计占最近两年营业收入合计比例不低于8%。<br/>※标准四：<br/>市值≥15亿元；<br/>最近两年研发投入合计不低于5000万元。'
  },
  // Copyright
  copyright: {
    CULTURE: '融智、融资、融梦想 创新、创业、创未来',
    CONTACT_INFORMATION: '联系方式',
    ADDRESS_TEXT: '地址',
    EMAIL: '邮箱',
    TEL: '电话',
    ADDRESS_DETAIL: '中国深圳市南山区前海世贸大厦1903(铁路公园站D出口)'
  },
  // 上市前孵化服务
  pattern: {
    title: '商业模式及商业计划书服务',
    pattrrm1: 'BP辅导',
    pattrrm1center: 'BP定制服务包含一对一咨询、项目定位、从行业研究分析、投资亮点提取、商业逻辑梳理、盈利模式分析、估值建议、发展规划、设计编排，再到给到创业者一些如何与投资人高效沟通的注意事项等内容服务。',
    pattrrm2: '商业模式设计',
    pattrrm1center2: '从战略到战术，从现金流到盈利，从销售到营销，为企业从更高纬度提供经营规划。',
    pattrrm3: '商业计划书',
    pattrrm1center3: '帮助企业制定专业的商业计划书，让企业与投资方更好地沟通，让企业快速融资。',
    pattrrm4: '路演',
    pattrrm1center4: '培训商业计划书演讲者的演讲逻辑能力和临场交流回答能力，让演讲者能够快速地打动潜在投资人。'
  },
  // 合规管理
  management: {
    title: '合规管理',
    text1: '市场交易',
    text2: '安全环保',
    text3: '产品质量',
    text4: '劳动用工',
    text5: '财务税收',
    text6: '知识产权',
    text7: '商业伙伴'
  },
  // 合规管理
  brand: {
    title: '品牌孵化',
    text1: '媒体矩阵搭建',
    text2: '品牌营销咨询',
    text3: '品牌口碑建设',
    text4: '营销推广策划',
    text5: '活动/会务执行'
  },
  Brandandsales: {
    TITLE: '品销合一',
    center: '中科融创为企业提供品销合一的运营服务，包括品牌框架搭建、新媒体运营、品牌主播孵化、平台管理等，帮助企业实现品牌宣传与产品销售的目的，为上市作数据积累与支撑。'
  },
  camp: {
    TITLE: '新媒体运营孵化培训营',
    center: '中科融创新媒体运营孵化营主要孵化网红主播、短视频运营者，课程侧重内容框架梳理与人设/品牌定位。帮助企业与个人创业者通过新媒体打造特有IP，为事业注入活力；帮助企业找到合适的品牌方向，打造品牌网红，扩大品牌知名度，为销售赋能。'
  },
  newcenter: {
    menu1: '全部',
    menu2: '行业资讯',
    menu3: '公司动态',
    menu4: '项目动态',
    viewdetails: '查看详情',
    return: '返回列表'
  },
  sponsorList: {
    url1: require('../assets/union_1.png'),
    url_mob1: require('../assets/union_mob_1.png'),
    dialog_url1: require('../assets/avatar1.1.png'),
    title1: 'Simon Lam',
    introduction1: ['斯普林律师事务所上市保荐商代理人、集团顾问', '纳斯达克(北欧)交易所高增长市场保荐人中国区总裁'],
    text1: '精通各国股票交易所跨境金融法，早期毕业于瑞士RITZ恺撒里兹酒店管理大学，毕业后加入香港老牌英资怡和集团JARDINE毕业生“行政管理”课程。移居英国先后成为英国董事学会IoD资深会员(1998)、英国48家集团成员。2004年在中国深圳大学完成了中国法律深造课程，曾担任伦敦证交所 “高端”主板上市公司ALLIED DUNBAR PLC，现分管中国喜睦律师事务所欧洲上市并购部部门主管，上市辅导的成功案例超过50个。',
    url2: require('../assets/union_2.png'),
    url_mob2: require('../assets/union_mob_2.png'),
    dialog_url2: require('../assets/avatar2.1.png'),
    title2: 'David Heron',
    introduction2: ['纳斯达克第一北市保荐人', '斯普林（香港）有限公司名誉主席'],
    text2: 'David Heron在管理、销售和分销领域从事金融服务35年，曾任香港AM Capital创始合伙人兼常务董事、Info Point International主席、菲律宾E*Trade Securities总经理、Sapphire Securities副总裁、Prime East Securities副董事、香港格罗夫纳行政财务管理有限公司董事总经理，熟悉私募股权交易、资产管理和公司财务、二级市场股票投资、IPO投资、pre-IPO投资等。',
    url3: require('../assets/union_3.png'),
    url_mob3: require('../assets/union_mob_3.png'),
    dialog_url3: require('../assets/avatar3.1.png'),
    title3: 'Ansis Spridzans',
    introduction3: ['CDO律师执行合伙人', '斯普林律师事务所创始合伙人'],
    text3: 'Ansis Spridzans拥有超过17年的法律业务经验，曾在多家银行和金融、资本市场做并购交易，在拉脱维亚市场的许多具有里程碑意义的并购交易中担任重要角色，尤其对东欧和后苏联地区金融、投资和资本市场的法律方面有深入的了解。安西斯·斯普里詹斯在担任私有化机构主席期间，获得丰富的行政、政府和政治程序方面的经验。',
    url4: require('../assets/union_4.png'),
    url_mob4: require('../assets/union_mob_4.png'),
    dialog_url4: require('../assets/avatar4.1.png'),
    title4: '梅晓斌',
    introduction4: ['当代儒商', '天使投资人', '和合国际集团创始人'],
    text4: '深厚文化素养、虔诚的佛教徒，曾入职多家企业总经理，参与大型上市公司的上市操作，熟悉股权结构设计，有丰富的企业管理经验。',
    url5: require('../assets/union_5.png'),
    url_mob5: require('../assets/union_mob_5.png'),
    dialog_url5: require('../assets/avatar5.1.png'),
    title5: '贾杰森',
    introduction5: ['政企服务专家'],
    text5: '贾杰森先生是工信部国家创业辅导师、中小微创业创新服务专家、广东省科技企业孵化器协会创业导师、蚁路公益基金联合发起人、蚂蚁企服联合创始人，中科融创总经理，拥有超过10年政企服务经验，为上百家中国企业提供创业指导和上市辅导。',
    url6: require('../assets/union_6.png'),
    url_mob6: require('../assets/union_mob_6.png'),
    dialog_url6: require('../assets/avatar6.1.png'),
    title6: '余旭',
    introduction6: ['品牌孵化专家'],
    text6: '中科融创联合创始人、中国科学院武汉分院创业导师、互联网多次创业者、商业模式规划专家、新媒体运营专家、资深产品经理人，对行业趋势、消费趋势、国家趋势有独特理解和运用经验。',
    url7: require('../assets/union_7.png'),
    url_mob7: require('../assets/union_mob_7.png'),
    dialog_url7: require('../assets/avatar7.1.png'),
    title7: '王华斌',
    introduction7: ['企业组织系统、股权激励、资本孵化专家'],
    text7: 'MBA中国赢销体系创始人，服务超过200家中小企业，专注为企业提供组织系统、股权激励、众筹融资、阿米巴等模块服务，在企业规范化、营销业绩倍增、债券股权等领域拥有卓越的专家辅导能力，赋能企业快速进入资本市场。',
    url8: require('../assets/union_8.png'),
    url_mob8: require('../assets/union_mob_8.png'),
    dialog_url8: require('../assets/avatar8.1.png'),
    title8: '严敏',
    introduction8: ['德恒律师事务所合伙人'],
    text8: 'MBA中国赢销体系创始人，服务超过200家中小企业，专注为企业提供组织系统、股权激励、众筹融资、阿米巴等模块服务，在企业规范化、营销业绩倍增、债券股权等领域拥有卓越的专家辅导能力，赋能企业快速进入资本市场。'
  },
  imgList: {
    img1: require('../assets/home_banner.png'),
    img2: require('../assets/banner-ronzi.png'),
    img3: require('../assets/banner-fuhua.png'),
    img4: require('../assets/banner-news-center.png')
  },
  abroadbadyimg: {
    img: require('../assets/service_way_img_2a.png'),
    stepone: '第3步',
    steptwo: '第2步',
    stepthree: '第1步',
    step1text: '从美股转板到香港交易所主板上市',
    step2text: '在纳斯达克（美国）或伦敦交易所IPO',
    step3text: '先在纳斯达克（北欧）-CSD挂牌，然后在纳斯达克（北欧）-第一北市IPO，为未来上市做基础铺路'
  },
  ideaurl: {
    img1: require('../assets/home_culture_linian_mob.png'),
    img2: require('../assets/home_culture_value_mob.png'),
    img3: require('../assets/home_culture_forward_mob.png')
  },
  // 首页
  // index:{
  //   SERVICES:'主营业务',
  //   NEWS:'最新资讯',
  //   GOLDMATENEWS:'公司要闻',
  //   MARKETINSIGHT:'汇市动态',
  //   KUNDAX:'坤达速汇',
  //   GOLDMATE:'中联汇兑',
  //   GOLDMATEGROUP:'中联集团介绍',
  //   KUNDAGROUP:'坤达集团介绍'
  // }
  // bp辅导
  bpCoach: {
    // 页面导航
    breadcrumb: {
      toTitle: '上市前孵化服务',
      selfTitle: 'BP辅导'
    },
    // 标题
    contentTitle: {
      solution: 'BP定制解决方案',
      server: '服务保障·我们更懂投资人',
      custom: 'BP定制服务流程',
      case: 'BP定制服务案例',
      organ: '合作投资机构'
    },
    // 计划书
    tip: {
      pcUrl: require('../assets/bp/bp_tip.png'),
      h5Url: require('../assets/bp/bp_tip_web.png')
    },
    // BP定制解决方案
    solution: [
      {
        url: require('../assets/bp/bp_icon_data.png'),
        title: '数据',
        text: '依托全渠道、多维度的一二级市场数据库，辅助客户挖掘其项目相关领域数据背后隐藏的巨大商业价值。'
      },
      {
        url: require('../assets/bp/bp_icon_study.png'),
        title: '研究',
        text: '基于资深分析师的行业经验，形成全方位的市场分析、行业分析、定向企业分析研究成果。'
      },
      {
        url: require('../assets/bp/bp_icon_consult.png'),
        title: '咨询',
        text: '资深分析师全程跟进，服务期间进行竞品研究、拟定合理的未来发展预期、进行合理的估值区间建议。'
      },
      {
        url: require('../assets/bp/bp_icon_report.png'),
        title: 'BP报告',
        text: '结合完整的投融资数据及分析师长期积累，从企业估值、商业逻辑梳理、盈利模式分析、发展战略规划、财务信息等多方面呈现企业发展情况，定制投资人最想要的商业计划书。'
      }
    ],
    server: {
      pcUrl: [
        { url: require('../assets/bp/bp_service_1.png') },
        { url: require('../assets/bp/bp_service_2.png') },
        { url: require('../assets/bp/bp_service_3.png') }
      ],
      h5Url: [
        { url: require('../assets/bp/bp_service_web_1.png') },
        { url: require('../assets/bp/bp_service_web_2.png') },
        { url: require('../assets/bp/bp_service_web_3.png') }
      ]
    },
    // BP定制服务流程
    custom: {
      pcUrl: require('../assets/bp/bp_custom.png'),
      h5Url: [
        {
          url: require('../assets/bp/bp_custom_web_1.png'),
          title: '提交需求',
          text: '项目初筛，匹配分析师，需求沟通。'
        },
        {
          url: require('../assets/bp/bp_custom_web_2.png'),
          title: '咨询洽谈',
          text: '前期访谈、调研、分析，提供定制化方案，根据用户需求以及服务成本进行合理报价。'
        },
        {
          url: require('../assets/bp/bp_custom_web_3.png'),
          title: '合同签署',
          text: '签署正规合同，保障服务质量，维护客户权益，付费后开具发票。'
        },
        {
          url: require('../assets/bp/bp_custom_web_4.png'),
          title: '分析师访谈',
          text: '资深分析师1V1深度访谈、根据项目所属阶段进行定制化服务。'
        },
        {
          url: require('../assets/bp/bp_custom_web_5.png'),
          title: 'BP定制',
          text: '项目定位、商业逻辑梳理、盈利模式分析、投资亮点提取、发展规划、估值建议等。'
        }
      ]
    },
    // BP定制服务案例图片
    case: {
      pcUrl: require('../assets/bp/bp_case.png'),
      h5Url: [
        { url: require('../assets/bp/bp_case_web_1.png') },
        { url: require('../assets/bp/bp_case_web_2.png') },
        { url: require('../assets/bp/bp_case_web_3.png') },
        { url: require('../assets/bp/bp_case_web_4.png') },
        { url: require('../assets/bp/bp_case_web_5.png') },
        { url: require('../assets/bp/bp_case_web_6.png') }
      ]
    },
    // 合作投资机构
    organ: {
      pcUrl: require('../assets/bp/bp_organ.png'),
      h5Url: require('../assets/bp/bp_organ_web.png')
    }
  },
  // 上市孵化服务新增版块
  incubation: {
    title: '企业注册资本实缴',
    capitalList: [
      {
        title: '认缴VS实缴',
        text: '从2014年3月1日 开始实施将注册资本实缴登记制改为认缴登记制，并放宽工商登记其他条件。由此公司注册资本就随心写，以此彰显公司实力。但为此，也给出资股东埋下了风险隐患。《公司法》第三条规定“有限责任公司的股东以其认缴的出资额为限对公司承担责任；股份有限公司的股东以其认购的股份为限对公司承担责任”。一旦企业经营过程中出现债务风险，股东是认缴未实缴，需要依照认缴金额为限追究股东责任。',
        pcUrl: require('@/assets/incubation/capital_pc_1.png'),
        h5Url: require('@/assets/incubation/capital_h5_1.png'),
        isOpen: false
      },
      {
        title: '实缴方式',
        text: '《公司法》第二十七条规定：股东可以用货币出资，也可以用实物、知识产权、土地使用权等可以用货币估价并可以依法转让的非货币财产作价出资；因此当股东完成了所在公司的实缴义务，就自然与公司经营的风险进行了剥离。《公司法》第三条规定“公司以其全部财产对公司的债务承担责任。”股东完成实缴义务后无须再承担额外责任。',
        pcUrl: require('@/assets/incubation/capital_pc_2.png'),
        h5Url: require('@/assets/incubation/capital_h5_2.png'),
        isOpen: false
      }
    ],
    childTitle: '操作流程',
    capitalData: [
      {
        url: require('@/assets/bp/bp_custom_web_1.png'),
        title: '签订合同',
        text: '签订合同并提供增资材料'
      },
      {
        url: require('@/assets/bp/bp_custom_web_2.png'),
        title: '整理材料',
        text: '关联公司对材料做出相关筛选,提取和整理，进行评估工作'
      },
      {
        url: require('@/assets/bp/bp_custom_web_3.png'),
        title: '价值评估',
        text: '评估完成出具评估报告书和验资报告，有业内资深的专家确认签字'
      },
      {
        url: require('@/assets/bp/bp_custom_web_4.png'),
        title: '工商审核',
        text: '工商专员将带着报告去贵公司进行股东签字，并到当地工商进行审核，一审二审通过，完成工商办理'
      },
      {
        url: require('@/assets/bp/bp_custom_web_5.png'),
        title: '成功实缴/增资',
        text: '完成实缴/增资'
      }
    ]
  }
}
