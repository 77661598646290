<!--  -->
<template>
<div class="copyright">
    <div class="top">
    <div class="contact-way">
        <p class="title">{{$t('lang.copyright.CONTACT_INFORMATION')}}</p>
        <p>{{$t('lang.copyright.ADDRESS_TEXT')}}: {{$t('lang.copyright.ADDRESS_DETAIL')}}</p>
        <p>{{$t('lang.copyright.EMAIL')}}: casfi@foxmail.com</p>
        <p>{{$t('lang.copyright.TEL')}}: 18018797362</p>
    </div>
    <div class="synopsis">
        <img src="../../assets/home_copyright_logo.png" class="logo" alt="">
        <span>{{$t('lang.copyright.CULTURE')}}</span>
    </div>
    </div>
    <div class="bottom">
        <span>法律声明</span>
        <span>阳光准则</span>
        <span>网站地图</span>
        <span><a href="https://beian.miit.gov.cn/#/Integrated/index">ICP证：粤ICP备2021126927号</a></span>
        <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008213">
          <img src="../../assets/Policemen.jpg" alt="" />
          粤公网安备44030502008213
        </a></span>
    </div>
</div>
</template>

<script>
export default {
  name: 'Copyright',
  data () {
    return {

    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.copyright {
    background: #000;
    font-size: 12px;
    line-height: 18px;
    .top {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        max-width: 1600px;
        margin: auto;
        padding-top: 80px;
        .synopsis {
            display: flex;
            align-items: center;
            color: white;
            margin-right: 100px;
            .logo {
                margin-right: 10px;
            }
        }
        .contact-way {
            color: #999;
            margin-left: 100px;
            .title {
                font-size: 16px;
                margin-bottom: 15px;
                font-weight: bold;
                color: white;
            }
        }
    }
    .bottom {
        border-top: 1px solid #999;
        color: white;
        margin: auto;
        margin-top: 130px;
        width: 1200px;
        max-width: 1600px;
        padding: 60px 0;
        span {
            margin-right: 20px;

            a {
                color: white;

                img {
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                }
            }
        }
    }
}
</style>
