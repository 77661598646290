<template>
  <div class="home">
    <div class="container">
      <div class="Header-item"><Header @getnowtype="getnowtype"/></div>
      <div class="">
        <Carousel/>
      </div>
      <div class="content">
        <div class="culture-item">
      <div class="culture" v-if="iswindow">
        <div class="img-item">
          <div class="title-all">
            <div class="title">{{ $t("lang.value.PHILOSOPHY")}}</div>
            <div class="center">{{ $t("lang.value.PHILOSOPHYCENTER")}}</div>
          </div>
          <img src="../assets/home_head_icon_linians.png"/>
        </div>
        <div class="img-item">
          <div class="title-all">
            <div class="title">{{ $t("lang.value.OURVALUE")}}</div>
            <div class="center">{{ $t("lang.value.OURVALUECENTER")}}</div>
          </div>
          <img src="../assets/home_head_icon_values.png"/>
        </div>
        <div class="img-item">
          <div class="title-all">
            <div class="title">{{ $t("lang.value.VISION")}}</div>
            <div class="center">{{ $t("lang.value.VISIONCENTER")}}</div>
            <div class="center">{{ $t("lang.value.VISIONCENTER2")}}</div>
          </div>
          <img src="../assets/home_head_icon_forwards.png"/>
        </div>
        </div>
      </div>
        <!-- 公司文化模块 -->
        <div class="culture" v-if="!iswindow">
          <img
            :src="item.url"
            class="culture-img"
            v-for="(item, index) in cultureList"
            :key="index"
            alt=""
          />
        </div>
        <!-- 上市融资服务模块 -->
        <div class="service">
          <div class="home-title"  @click="goOtherrouter(1,'financing_service')">
            <div class="title">{{ $t("lang.financing.SERVICES") }}</div>
            <div class="more"
              >{{ $t("lang.financing.MORE") }} <i class="el-icon-arrow-right right-icon"></i
            ></div>
          </div>
          <div class="service-content">
            <div
              class="service-item"
            >
              <img src="../assets/home_service_icon_1.png" alt="" class="service-img-round" />
              <span class="service-text">{{ $t("lang.financing.TRADEABROAD") }}</span>
            </div>
             <div
              class="service-item"
            >
              <img src="../assets/home_service_icon_2.png" alt="" class="service-img-round" />
              <span class="service-text">{{ $t("lang.financing.VATSPECIAL") }}</span>
            </div>
             <div
              class="service-item"
            >
              <img src="../assets/home_service_icon_3.png" alt="" class="service-img-round" />
              <span class="service-text">{{ $t("lang.financing.MAINLAND") }}</span>
            </div>
             <div
              class="service-item"
            >
              <img src="../assets/home_service_icon_4.png" alt="" class="service-img-round" />
              <span class="service-text">{{ $t("lang.financing.INTELLECTUAL") }}</span>
            </div>
          </div>
        </div>
        <!-- 上市前孵化服务模块 -->
        <div class="service">
          <div class="home-title" @click="goOtherrouter(2,'incubation_service')">
            <span class="title">{{ $t("lang.service.TITLE") }}</span>
            <span class="more"
              >{{ $t("lang.service.MORE") }} <i class="el-icon-arrow-right right-icon"></i
            ></span>
          </div>
          <div class="service-content">
            <div
              class="service-item"
              v-for="(item, index) in incubationList"
              :key="index"
            >
              <div class="incubation-all">
              <div class="title">{{item.title}}</div>
              <img :src="item.url" alt="" class="incubation-img-web" />
              </div>
              <div class="incubation-alls">
              <div class="title">{{item.title}}</div>
                <img :src="item.url_mob" alt="" class="incubation-img-mob" />
              </div>
            </div>
          </div>
        </div>
        <!-- 上市保荐人模块 -->
        <div class="sponsor">
          <div class="home-title">
            <span class="title">{{ $t("lang.atrocinador.TITLE") }}</span>
          </div>
          <div class="sponsor-item">
            <img
              :src="item.url"
              alt=""
              v-for="(item, index) in sponsorList"
              :key="item.title"
              class="sponsor-img-web"
              @mouseover="handleOpenDialog(index)"
            />
            <img
              :src="item.url_mob"
              alt=""
              v-for="(item, index) in sponsorList"
              :key="index"
              class="sponsor-img-mob"
              @click="handleOpenDialog(index)"
            />
            <div></div>
          </div>
        </div>
        <!-- 新闻中心模块 -->
        <div class="news-center">
          <div class="home-title" @click="goOtherrouter(3,'news_center')" >
            <span class="title">{{ $t("lang.journalism.TITLE") }}</span>
            <span class="more"
              >{{ $t("lang.journalism.MORE") }} <i class="el-icon-arrow-right right-icon"></i
            ></span>
          </div>
          <div class="home-news-content-web">
            <div
              class="news-item-web"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <img :src="item.img" alt="" class="news-img-web" />
              <div class="news-box">
                <p class="news-title">{{ item.title }}</p>
                <p class="news-text">{{ item.digest }}</p>
                <p class="news-detail" @click="getViewdetails(item)">{{ $t('lang.journalism.details')}}</p>
              </div>
            </div>
          </div>
          <div class="home-news-content-mob">
            <img :src="newsList[0].img" alt="" class="home-news-img-mob" />
            <div class="news-box">
              <p class="news-title">{{ newsList[0].title }}</p>
              <p class="news-text">{{ newsList[0].digest }}</p>
            </div>
            <div class="item-mob-bottom">
              <p class="data">{{ newsList[0].create_time}}</p>
              <p class="detail" @click="getViewdetailsmob(newsList[0])">{{ $t("lang.journalism.details") }} <i class="el-icon-arrow-right"></i></p>
            </div>
          </div>
        </div>
        <!-- 案例模块 -->
        <div class="case-box">
          <div class="case">
            <div class="home-title">
              <span class="title">{{ $t("lang.case.TITLE") }}</span>
            </div>
            <div class="case-content">
              <el-tooltip
                v-for="(item, index) in caseList"
                :key="index"
                class="case-item"
                effect="dark"
                placement="top"
                :content="item.text"
                popper-class="tip-class"
              >
                <img :src="item.url" alt="" class="case-img" />
              </el-tooltip>
              <div
                class="case-item-mob"
                v-for="item in caseList"
                :key="item.url"
              >
                <img :src="item.url" alt="" class="case-img-mob" />
                <!-- <p class="case-title">{{ item.title }}</p> -->
              </div>
            </div>
        </div>
        <!-- 对话框 -->
        <InfoDialog
          v-bind:dialogVisible="externalDialogVisible"
          v-bind:url="url"
          v-bind:title="title"
          v-bind:introduction="introduction"
          v-bind:text="text"
          @handleCloseDialog="handleCloseDialog"
        />
      </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import InfoDialog from '@/components/InfoDialog/InfoDialog.vue'
import Copyright from '@/components/Copyright/Copyright.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    Carousel,
    InfoDialog,
    Copyright
  },
  data () {
    return {
      timerFun: null,
      cultureList: [],
      iswindow: true, // 判断是什么pc端还是移动端
      financingList: [
        {
          url: require('../assets/home_service_icon_1.png'),
          title: '最有效的境外交易途径'
        },
        {
          url: require('../assets/home_service_icon_2.png'),
          title: '境外交易所'
        },
        {
          url: require('../assets/home_service_icon_3.png'),
          title: '内地交易所'
        },
        {
          url: require('../assets/home_service_icon_4.png'),
          title: '资产证券化'
        }
      ],
      incubationList: [
        {
          url: require('../assets/home_service2_icon_1.png'),
          url_mob: require('../assets/home_service2_icon_1@3x.png'),
          title: '商业模式企划书'
        },
        {
          url: require('../assets/home_service2_icon_2.png'),
          url_mob: require('../assets/home_service_icon_2@3x.png'),
          title: '品牌新趋势'
        },
        {
          url: require('../assets/home_service2_icon_3.png'),
          url_mob: require('../assets/home_service_icon_3@3x.png'),
          title: '营销新方式'
        },
        {
          url: require('../assets/home_service2_icon_4.png'),
          url_mob: require('../assets/home_service_icon_4@3x.png'),
          title: '营销新工具'
        },
        {
          url: require('../assets/home_service_icon_5.png'),
          url_mob: require('../assets/home_service_icon_5@3x.png'),
          title: '品牌孵化'
        },
        {
          url: require('../assets/home_service_icon_6.png'),
          url_mob: require('../assets/home_service_icon_6@3x.png'),
          title: '合规管理'
        },
        {
          url: require('../assets/home_service_icon_7.png'),
          url_mob: require('../assets/home_service_icon_7@3x.png'),
          title: '私募股权投资服务'
        }
      ],
      sponsorList: [
        {
          url: require('../assets/union_1.png'),
          url_mob: require('../assets/union_mob_1.png'),
          dialog_url: require('../assets/avatar1.1.png'),
          title: 'Simon Lam',
          introduction: ['斯普林律师事务所上市保荐商代理人、集团顾问', '纳斯达克(北欧)交易所高增长市场保荐人中国区总裁'],
          text: '精通各国股票交易所跨境金融法，早期毕业于瑞士RITZ恺撒里兹酒店管理大学，毕业后加入香港老牌英资怡和集团JARDINE毕业生“行政管理”课程。移居英国先后成为英国董事学会IoD资深会员(1998)、英国48家集团成员。2004年在中国深圳大学完成了中国法律深造课程，曾担任伦敦证交所 “高端”主板上市公司ALLIED DUNBAR PLC，现分管中国喜睦律师事务所欧洲上市并购部部门主管，上市辅导的成功案例超过50个。'
        },
        {
          url: require('../assets/union_2.png'),
          url_mob: require('../assets/union_mob_2.png'),
          dialog_url: require('../assets/avatar2.1.png'),
          title: 'David Heron',
          introduction: ['纳斯达克第一北市保荐人', '斯普林（香港）有限公司名誉主席'],
          text: 'David Heron在管理、销售和分销领域从事金融服务35年，曾任香港AM Capital创始合伙人兼常务董事、Info Point International主席、菲律宾E*Trade Securities总经理、Sapphire Securities副总裁、Prime East Securities副董事、香港格罗夫纳行政财务管理有限公司董事总经理，熟悉私募股权交易、资产管理和公司财务、二级市场股票投资、IPO投资、pre-IPO投资等。'
        },
        {
          url: require('../assets/union_3.png'),
          url_mob: require('../assets/union_mob_3.png'),
          dialog_url: require('../assets/avatar3.1.png'),
          title: 'Ansis Spridzans',
          introduction: ['CDO律师执行合伙人', '斯普林律师事务所创始合伙人'],
          text: 'Ansis Spridzans拥有超过17年的法律业务经验，曾在多家银行和金融、资本市场做并购交易，在拉脱维亚市场的许多具有里程碑意义的并购交易中担任重要角色，尤其对东欧和后苏联地区金融、投资和资本市场的法律方面有深入的了解。安西斯·斯普里詹斯在担任私有化机构主席期间，获得丰富的行政、政府和政治程序方面的经验。'
        },
        {
          url: require('../assets/union_4.png'),
          url_mob: require('../assets/union_mob_4.png'),
          dialog_url: require('../assets/avatar4.1.png'),
          title: '梅晓斌',
          introduction: ['当代儒商', '天使投资人', '和合国际集团创始人'],
          text: '深厚文化素养、虔诚的佛教徒，曾入职多家企业总经理，参与大型上市公司的上市操作，熟悉股权结构设计，有丰富的企业管理经验。'
        },
        {
          url: require('../assets/union_5.png'),
          url_mob: require('../assets/union_mob_5.png'),
          dialog_url: require('../assets/avatar5.1.png'),
          title: '贾杰森',
          introduction: ['政企服务专家'],
          text: '贾杰森先生是工信部国家创业辅导师、中小微创业创新服务专家、广东省科技企业孵化器协会创业导师、蚁路公益基金联合发起人、蚂蚁企服联合创始人，中科融创总经理，拥有超过10年政企服务经验，为上百家中国企业提供创业指导和上市辅导。'
        },
        {
          url: require('../assets/union_6.png'),
          url_mob: require('../assets/union_mob_6.png'),
          dialog_url: require('../assets/avatar6.1.png'),
          title: '余旭',
          introduction: ['品牌孵化专家'],
          text: '中科融创联合创始人、中国科学院武汉分院创业导师、互联网多次创业者、商业模式规划专家、新媒体运营专家、资深产品经理人，对行业趋势、消费趋势、国家趋势有独特理解和运用经验。'
        },
        {
          url: require('../assets/union_7.png'),
          url_mob: require('../assets/union_mob_7.png'),
          dialog_url: require('../assets/avatar7.1.png'),
          title: '王华斌',
          introduction: ['企业组织系统、股权激励、资本孵化专家'],
          text: 'MBA中国赢销体系创始人，服务超过200家中小企业，专注为企业提供组织系统、股权激励、众筹融资、阿米巴等模块服务，在企业规范化、营销业绩倍增、债券股权等领域拥有卓越的专家辅导能力，赋能企业快速进入资本市场。'
        },
        {
          url: require('../assets/union_8.png'),
          url_mob: require('../assets/union_mob_8.png'),
          dialog_url: require('../assets/avatar8.1.png'),
          title: '严敏',
          introduction: ['德恒律师事务所合伙人'],
          text: 'MBA中国赢销体系创始人，服务超过200家中小企业，专注为企业提供组织系统、股权激励、众筹融资、阿米巴等模块服务，在企业规范化、营销业绩倍增、债券股权等领域拥有卓越的专家辅导能力，赋能企业快速进入资本市场。'
        }
      ],
      newsList: [
        {
          img: '',
          title: '',
          digest: ''
        }
      ],
      date: [],
      caseList: [
        {
          url: require('../assets/home_case_img1.png'),
          title: '创新工场',
          text: '由李开复博士创办，致力于早期阶段投资，并提供全方位创业培育的投资机构与创业平台，旨在培育创新人才和新一代高科技企业。'
        },
        {
          url: require('../assets/home_case_img2.png'),
          title: '深圳市科技金融促进会',
          text: '由深圳科技界、金融界企业单位自愿组成，秉持非盈利性、互帮互助为原则的经济类社团组织。'
        },
        {
          url: require('../assets/home_case_img3.png'),
          title: '木森林',
          text: '集LED封装与LED应用产品为一体的综合性光电企业，拥有高效的生产、研发和检测设备及先进的生产管理技术，已成为全球知名LED生产企业。'
        },
        {
          url: require('../assets/home_case_img4.png'),
          title: '清科创业中心',
          text: '是中国领先的创业与投资综合服务商及知名投资机构，旗下业务包含：清科创业研究院、沙丘学堂、项目工场。'

        },
        {
          url: require('../assets/home_case_img5.png'),
          title: '师董会',
          text: '专注于全球商业智慧共享及实战互助的智联网公司，以信息服务、知识共享服务、企业生态服务、科技孵化器、教育培训服务、投融资服务、产业中国项目等为主营的数字化国家级高新技术企业。'
        },
        {
          url: require('../assets/home_case_img6.png'),
          title: '小鹅通',
          text: '以知识产品与用户服务为核心的技术服务商，创始至今已服务逾百万家客户，产品包含知识店铺、企业直播、企业培训等，为企业提供线上经营、商业变现、私域运营等一站式服务。'
        }
      ],
      externalDialogVisible: false,
      url: '',
      title: '',
      introduction: '',
      text: ''
    }
  },
  computed: {
    ...mapGetters(['getLangIndex', 'getLangSelect'])
  },
  watch: {
    getLangIndex (to, from) {
      this.cultureList = [
        { url: this.$t('lang.ideaurl.img1') },
        { url: this.$t('lang.ideaurl.img2') },
        { url: this.$t('lang.ideaurl.img3') }]
      this.getNewsList()
    }
  },
  methods: {
    // 获取详情
    getViewdetails (item) {
      this.$store.dispatch('setNavIndex', 3)
      this.$router.push({ path: 'newschild', query: { id: item.id } })
    },
    // 移动端的查看详情
    getViewdetailsmob (e) {
      this.$router.push({ path: 'newscenter', query: { id: e.id } })
    },
    // 设置企业服务默认值
    incubationserver () {
      this.incubationList = [
        {
          url: require('../assets/home_service2_icon_1.png'),
          url_mob: require('../assets/home_service2_icon_1@3x.png'),
          title: this.$t('lang.service.listtitle1')
        },
        {
          url: require('../assets/home_service2_icon_2.png'),
          url_mob: require('../assets/home_service_icon_2@3x.png'),
          title: this.$t('lang.service.listtitle2')
        },
        {
          url: require('../assets/home_service2_icon_3.png'),
          url_mob: require('../assets/home_service_icon_3@3x.png'),
          title: this.$t('lang.service.listtitle3')
        },
        {
          url: require('../assets/home_service2_icon_4.png'),
          url_mob: require('../assets/home_service_icon_4@3x.png'),
          title: this.$t('lang.service.listtitle4')
        },
        {
          url: require('../assets/home_service_icon_5.png'),
          url_mob: require('../assets/home_service_icon_5@3x.png'),
          title: this.$t('lang.service.listtitle5')
        },
        {
          url: require('../assets/home_service_icon_6.png'),
          url_mob: require('../assets/home_service_icon_6@3x.png'),
          title: this.$t('lang.service.listtitle6')
        },
        {
          url: require('../assets/home_service_icon_7.png'),
          url_mob: require('../assets/home_service_icon_7@3x.png'),
          title: this.$t('lang.service.listtitle7')
        }
      ]
    },
    caselist () {
      this.caseList = [
        {
          url: require('../assets/home_case_img1.png'),
          title: this.$t('lang.case.case1title'),
          text: this.$t('lang.case.case1')
        },
        {
          url: require('../assets/home_case_img2.png'),
          title: this.$t('lang.case.case2title'),
          text: this.$t('lang.case.case2')
        },
        {
          url: require('../assets/home_case_img3.png'),
          title: this.$t('lang.case.case3title'),
          text: this.$t('lang.case.case3')
        },
        {
          url: require('../assets/home_case_img4.png'),
          title: this.$t('lang.case.case4title'),
          text: this.$t('lang.case.case4')
        },
        {
          url: require('../assets/home_case_img5.png'),
          title: this.$t('lang.case.case5title'),
          text: this.$t('lang.case.case5')
        },
        {
          url: require('../assets/home_case_img6.png'),
          title: this.$t('lang.case.case6title'),
          text: this.$t('lang.case.case6')
        }
      ]
    },
    getsponsorList () {
      this.sponsorList = [
        {
          url: this.$t('lang.sponsorList.url1'),
          url_mob: this.$t('lang.sponsorList.url_mob1'),
          dialog_url: this.$t('lang.sponsorList.dialog_url1'),
          title: this.$t('lang.sponsorList.title1'),
          introduction: this.$t('lang.sponsorList.introduction1'),
          text: this.$t('lang.sponsorList.text1')
        },
        {
          url: this.$t('lang.sponsorList.url2'),
          url_mob: this.$t('lang.sponsorList.url_mob2'),
          dialog_url: this.$t('lang.sponsorList.dialog_url2'),
          title: this.$t('lang.sponsorList.title2'),
          introduction: this.$t('lang.sponsorList.introduction2'),
          text: this.$t('lang.sponsorList.text2')
        },
        {
          url: this.$t('lang.sponsorList.url3'),
          url_mob: this.$t('lang.sponsorList.url_mob3'),
          dialog_url: this.$t('lang.sponsorList.dialog_url3'),
          title: this.$t('lang.sponsorList.title3'),
          introduction: this.$t('lang.sponsorList.introduction3'),
          text: this.$t('lang.sponsorList.text3')
        },
        {
          url: this.$t('lang.sponsorList.url4'),
          url_mob: this.$t('lang.sponsorList.url_mob4'),
          dialog_url: this.$t('lang.sponsorList.dialog_url4'),
          title: this.$t('lang.sponsorList.title4'),
          introduction: this.$t('lang.sponsorList.introduction4'),
          text: this.$t('lang.sponsorList.text4')
        },
        {
          url: this.$t('lang.sponsorList.url5'),
          url_mob: this.$t('lang.sponsorList.url_mob5'),
          dialog_url: this.$t('lang.sponsorList.dialog_url5'),
          title: this.$t('lang.sponsorList.title5'),
          introduction: this.$t('lang.sponsorList.introduction5'),
          text: this.$t('lang.sponsorList.text5')
        },
        {
          url: this.$t('lang.sponsorList.url6'),
          url_mob: this.$t('lang.sponsorList.url_mob6'),
          dialog_url: this.$t('lang.sponsorList.dialog_url6'),
          title: this.$t('lang.sponsorList.title6'),
          introduction: this.$t('lang.sponsorList.introduction6'),
          text: this.$t('lang.sponsorList.text6')
        },
        {
          url: this.$t('lang.sponsorList.url7'),
          url_mob: this.$t('lang.sponsorList.url_mob7'),
          dialog_url: this.$t('lang.sponsorList.dialog_url7'),
          title: this.$t('lang.sponsorList.title7'),
          introduction: this.$t('lang.sponsorList.introduction7'),
          text: this.$t('lang.sponsorList.text7')
        },
        {
          url: this.$t('lang.sponsorList.url8'),
          url_mob: this.$t('lang.sponsorList.url_mob8'),
          dialog_url: this.$t('lang.sponsorList.dialog_url8'),
          title: this.$t('lang.sponsorList.title8'),
          introduction: this.$t('lang.sponsorList.introduction8'),
          text: this.$t('lang.sponsorList.text8')
        }
      ]
    },
    // 子组合传来的值
    getnowtype () {
      this.incubationserver()
      this.caselist()
      this.getsponsorList()
    },
    handleCloseDialog () {
      this.externalDialogVisible = false
    },
    handleOpenDialog (index) {
      if (index === 7) return
      if (this.timerFun) {
        clearTimeout(this.timerFun)
      }
      this.timerFun = setTimeout(() => {
        this.externalDialogVisible = true
        this.url = this.sponsorList[index].dialog_url
        this.title = this.sponsorList[index].title
        this.introduction = this.sponsorList[index].introduction
        this.text = this.sponsorList[index].text
      }, 500)
    },
    // 跳转不同的频道
    goOtherrouter (index, path) {
      this.$store.dispatch('setNavIndex', index)
      this.$router.push(path)
    },
    getNewsList () {
      // 请求新闻列表
      this.$axios.post('api/news/getfrontnewslist', { lang: this.getLangSelect }).then(res => {
        this.newsList = res.data.data.data.splice(0, 4)
        this.newsList.map(item => {
          const riqi = new Date(item.public_time * 1000)
          const month = riqi.getMonth() + 1
          this.date.push(riqi.getFullYear() + '/' + month + '/' + riqi.getDay())
        })
      }).catch(res => {
        console.log(res)
      })
    }
  },
  created () {
    this.$store.dispatch('setNavIndex', 0)
  },
  mounted () {
    this.incubationserver()
    this.caselist()
    this.getsponsorList()
    this.getNewsList()
    if (window.innerWidth <= 500) {
      this.iswindow = false
      this.cultureList = [
        { url: this.$t('lang.ideaurl.img1') },
        { url: this.$t('lang.ideaurl.img2') },
        { url: this.$t('lang.ideaurl.img3') }]
    } else {
      this.iswindow = true
    }
    window.onresize = () => {
      return (() => {
        if (window.innerWidth <= 500) {
          this.cultureList = [
            { url: this.$t('lang.ideaurl.img1') },
            { url: this.$t('lang.ideaurl.img2') },
            { url: this.$t('lang.ideaurl.img3') }]
        } else {
          this.cultureList = [
            { url: this.$t('lang.ideaurl.img1') },
            { url: this.$t('lang.ideaurl.img2') },
            { url: this.$t('lang.ideaurl.img3') }]
        }
      })()
    }
  }
}
</script>
<style scoped lang="less">
.container .culture-item{
    display: flex;
    justify-content: center;
    margin-top: -115px;
    position: relative;
    z-index: 11;
    width: 100%;
  // 公司文化
  .culture {
    width: 1200px;
    max-width: 1200px;
    display: flex;
    .culture-img {
      width: 33.33%;
      .img{
        width: 100%;
      }
    }
  }
}
.content {
  background: #f0f2f5;
  background-image: url("../assets/patern.png");
  background-size: 100% 100%;
  position: relative;
  .home-title {
    display: flex;
    justify-content: space-between;
    padding: 0 1vw;
    margin-bottom: 5vh;
    border-left: 3px solid #17cad0;
    font-weight: bold;
    font-size:1.17rem;
    .more {
      width: 8rem;
      text-align: right;
      color: #999;
      .right-icon {
        font-weight: bold;
      }
    }
  }
  // 公司文化
  .culture {
    padding: 0 5vw 5vh 5vw;
    display: flex;
    justify-content: center;
    .culture-img {
      width: 33.33%;
    }
  }
  // 孵化服务及融资服务
  .service {
    width: 1200px;
    max-width: 1200px;
    margin: auto;
    .service-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .service-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        margin-bottom: 5vh;
        .service-img-round {
          margin-bottom: 2vh;
          width: 50%;
        }
        .incubation-img-web {
          width: 100%;
        }
        .incubation-img-mob {
          width: 90%;
          display: none;
        }
        .service-text {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
  }
  // 上市保荐人联盟
  .sponsor {
    width: 1200px;
    max-width: 1200px;
    margin: auto;
    .sponsor-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5vh;
      .sponsor-img-web {
        width: 25%;
        cursor: pointer;
      }
      .sponsor-img-mob {
        display: none;
      }
    }
  }
  .home-title .title{
    font-size:1.17rem;
    flex: 1;
  }
  // 新闻中心
  .news-center {
    width: 1200px;
    max-width: 1200px;
    margin: auto;
    .home-title {
      margin: 0 auto;
      margin-bottom: 5vh;
    }
    .home-news-content-web {
      display: flex;
      padding: 0 2vw;
      flex-wrap: wrap;
      .news-item-web {
        display: flex;
        width: calc(50% - 20px);
        margin-bottom: 5vh;
        padding-right: 20px;
        .news-img-web {
          width: 220px;
          height: 150px;
          margin-right: 1vw;
          flex: none;
        }
        .news-img-web:hover{
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
        }
        .news-box {
          width: 450px;
          line-height: 25px;
          position: relative;
          .news-title {
            font-weight: bold;
            // padding-top: 2vh;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .news-text {
            font-size: 14px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
          .news-detail {
            font-size: 15px;
            color: #f30a0a;
            position: absolute;
            bottom: 0;
            cursor: pointer;
          }
        }
      }
    }
    .home-news-content-mob {
      padding: 16px 16px 0 16px;
      border-radius: 4px;
      background: #fff;
      display: none;
      .news-title {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .news-text {
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .item-mob-bottom {
        border-top: 1px solid #efefef;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        .data {
          font-size: 13px;
          color: #999;
        }
        .detail {
          font-size: 13px;
          color: #002537;
        }
      }
    }
  }
  // 案例
  .case-box {
    background-image: url("../assets/case_BG.png");
    background-size: 100% 100%;
    .case {
      width: 1200px;
      max-width: 1200px;
      margin: auto;
      padding-bottom: 218px;
      padding-top: 150px;
      .case-content {
        display: flex;
        justify-content: space-between;
        .case-item {
          display: flex;
          align-items: center;
          width: 15%;
          background: white;
          .case-img {
            width: 100%;
            display: block;
          }
        }
        .case-item-mob {
          display: none;
        }
      }
    }
  }
}
.container{
  .Header-item{
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 10;
    width: 100%;
  }
}
.img-item{
  position: relative;
  width: 33.3%;
  img{
    width: 100%;
  }
  .title{
    font-size: 1.5vw;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 15px;
  }
  .title-all{
    position: absolute;
    // top: 62%;
    text-align: center;
    width:100%;
    top: 50%;
    transform: translateY(-50%);
    .center{
      font-size: 0.7vw;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 5px;
    }
  }
}
.incubation-all{
  position: relative;
  display: flex;
  justify-content: center;
  .title{
    position:absolute;
    font-size: 1vw;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:-7px;
    width: 80%;
    margin: auto;
  }
}

.incubation-alls{
  position: relative;
  display: none;
  .title{
    position:absolute;
    font-size: 1vw;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content-all{
  width: 1200px;
  margin: auto;
}
</style>
