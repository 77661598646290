// 中文语言包
export const lang = {
  // nav
  navbar: {
    HOME: 'Ficha técnica',
    LISTING_FINANCING_SERVICE: 'Listagem de serviços de financiamento',
    PREIPO_INCUBATION_SERVICE: 'Serviços de incubação pré-mercado',
    NEWS_CENTER: 'Chinês simplificado',
    CHINESE: '简体中文',
    ENGLISH: 'English',
    PORTUGAES: 'Portugaes'
  },
  value: {
    PHILOSOPHY: 'A nossa filosofia',
    PHILOSOPHYCENTER: 'Gratidão, altruísmo, respeito, compreensão, inclusão',
    OURVALUE: 'Os nossos valores',
    OURVALUECENTER: 'Unidade de coração, de virtude, de caminhar e de viver juntos',
    VISION: 'A nossa visão',
    VISIONCENTER: 'Tornar o empreendedorismo em massa mais simples;',
    VISIONCENTER2: 'tornar os virtuosos ricos e os ricos virtuosos!'
  },
  financing: {
    SERVICES: 'Listagem interna e externa de serviços de financiamento para empresas chinesas',
    TRADEABROAD: 'A maneira mais eficiente de negociar fora das fronteiras',
    VATSPECIAL: 'Trocas no exterior',
    MAINLAND: 'Intercâmbio no continente',
    VISION: 'A nossa visão',
    INTELLECTUAL: 'Securitização de propriedade intelectual',
    MORE: 'Mais'
  },
  service: {
    TITLE: 'Serviços de incubação pré-mercado para empresas',
    listtitle1: 'Modelo de negócio livro de planejamento',
    listtitle2: 'Novas tendências da marca',
    listtitle3: 'Novas formas de marketing',
    listtitle4: 'Novas ferramentas de marketing',
    listtitle5: 'Incubação da marca',
    listtitle6: 'Gestão de conformidade',
    listtitle7: 'Serviços de investimento em private equity',
    MORE: 'Mais'
  },
  atrocinador: {
    TITLE: 'Listagem patrocinador aliança',
    MORE: 'Mais'
  },
  journalism: {
    TITLE: 'Centro de notícias',
    details: 'Ver mais detalhes',
    MORE: 'Mais'
  },
  case: {
    TITLE: 'Caso de vestuário empresarial',
    case1title: 'Sinovation Ventures',
    case1: 'Fundada pelo dr. kai-fu lee, dedica-se ao investimento em estágios iniciais e oferece uma gama completa de instituições de investimento e plataformas de empreendedorismo para promover o empreendedorismo, com o objetivo de fomentar talentos inovadores e uma nova geração de empresas de alta tecnologia.',
    case2title: 'Shenzhen Science and technology finance Promotion Association',
    case2: 'Composto voluntariamente pela comunidade da tecnologia de shenzhen, as unidades da comunidade financeira da empresa, aderindo à organização econômica da sociedade sem fins lucrativos e mútua como o princípio.',
    case3title: 'Forest Lighting',
    case3: 'Uma empresa optoeletrônica abrangente que integra produtos de embalagem e aplicação de LED. Com produção eficiente, equipamentos de p & d e testes e tecnologia avançada de gerenciamento de produção, tornou-se um fabricante de LED bem conhecido em todo o mundo.',
    case4title: 'Zero2IPO Group',
    case4: 'É um prestador de serviços abrangente líder de empreendedorismo e investimento na china e uma instituição de investimento bem conhecida. O negócio inclui: instituto de empreendedorismo qingke, dune gakuin, oficina de projeto.',
    case5title: 'Global Mentor Board ',
    case5: 'Empresa de rede inteligente que se concentra em compartilhamento de inteligência de negócios globais e ajuda mútua em combate real. É uma empresa de alta tecnologia nacional digital com serviços de informação, serviços de compartilhamento de conhecimento, serviços ecológicos corporativos, incubadoras de tecnologia, serviços de educação e treinamento, serviços de investimento e financiamento, projetos industriais da china, etc.',
    case6title: 'Xiao E',
    case6: 'Os produtos do conhecimento e os serviços do usuário como o núcleo do prestador de serviços de tecnologia, a fundação serviram mais de um milhão de clientes até agora, os produtos contêm a loja do conhecimento, a transmissão ao vivo da empresa, o treinamento da empresa, etc., para que as empresas forneçam o serviço de uma parada para a operação em linha, a realização comercial, a operação do domínio privado, etc.'
  },
  develop: {
    TITLE: 'Caminhos do capital para o desenvolvimento do negócio',
    img: require('../assets/service_way_img_1pt.png')
  },
  approach: {
    TITLE: 'O caminho mais eficaz para listar no exterior'
  },
  Foreign: {
    TITLE: 'Trocas no exterior',
    profit: 'Exigência de lucro: determinado pelo patrocinador',
    market: 'Requisito de capitalização de mercado: determinado pelo patrocinador',
    other: 'Tempo estabelecido: mais de meio ano',
    profit1: 'Necessidade de lucro: previsão para os próximos três anos',
    market1: 'Requisito de capitalização de mercado: determinado pelo patrocinador',
    other1: 'Requisitos de ativos: resultado auditado IFRS',
    profit2: 'Exigência de lucro: determinado pelo patrocinador',
    market2: 'Requisito de capitalização de mercado: mínimo 5 milhões de euros',
    other2: 'Requisitos de ativos: resultado auditado IFRS',
    profit3: 'Lucro necessário: us $7, 5 milhões',
    market3: 'Exigência de capitalização de mercado: us $50 milhões',
    profit4: 'Exigência de capitalização de mercado: us $75 milhões',
    market4: 'Ativos necessários: us $75 milhões',
    profit5: 'Padrão de lucro: nos últimos três anos',
    market5: 'Positivo e acumulado ≥11 milhões de dólares,',
    other5: '≥ us $2, 2 milhões por ano nos últimos dois anos',
    profit6: 'Capital social: mais de £700,000',
    market6: 'Exigência de capitalização de mercado: £25,000,000',
    profit7: 'Fluxo de caixa necessário: HKD 30 milhões',
    market7: 'Exigência de capitalização de mercado: HKD 150 milhões',
    profit8: 'Padrão de lucro: nos últimos três anos',
    market8: 'Positivo e acumulado ≥50 milhões HKD,',
    other8: 'Exigência de capitalização de mercado: HKD 500 milhões'
  },
  domestic: {
    TITLE: 'Trocas no território',
    SHENGZHEN: '※ Placa-mãe:<br/>Fluxo de caixa líquido acumulado das atividades operacionais nos últimos três anos > 50 milhões de dólares, ou faturamento operacional acumulado nos últimos três anos > 300 milhões de dólares;<br/>Ativos intangíveis não representem mais de 20% do ativo líquido no final do último período;<br/>Capital social total antes da emissão ≥30 milhões de RMB, após a emissão ≥50 milhões de RMB;<br/>O capital social total após a emissão ≤ 400 milhões de ações, a proporção de oferta pública deve ser ≥25%;<br/>O capital social total após a emissão > 400 milhões de ações. A proporção de oferta pública deve ser ≥10%.<br/>※ Placa gem:<br/>Capital social total após a emissão ≥30 milhões de RMB;<br/>Capital social total após a emissão ≤ 400 milhões de ações, a proporção de oferta pública deve ser ≥25%;<br/>O capital social total após a emissão > 400 milhões de ações. A proporção de oferta pública deve ser ≥10%.',
    SHANGHAI: '※ Placa-mãe:<br/>Fluxo de caixa líquido acumulado das atividades operacionais nos últimos três anos > 50 milhões de dólares, ou faturamento operacional acumulado nos últimos três anos > 300 milhões de dólares;<br/>Ativos intangíveis não representem mais de 20% do ativo líquido no final do último período;<br/>Capital social total antes da emissão ≥30 milhões de RMB, após a emissão ≥50 milhões de RMB;<br/>O capital social total após a emissão ≤ 400 milhões de ações, a proporção de oferta pública deve ser ≥25%;<br/>O capital social total após a emissão > 400 milhões de ações. A proporção de oferta pública deve ser ≥10%.<br/>※ Placa de cotron:<br/>Nos últimos três anos, o investimento em p & d representou mais de 5% do rendimento operacional, ou nos últimos três anos, a quantidade de investimento em p & d totalizou mais de 60 milhões de RMB;<br/>Capital social total após a emissão ≥30 milhões de RMB;<br/>O capital social total após a emissão ≤ 400 milhões de RMB, a proporção de oferta pública deve ser ≥25%;<br/>O capital social total após a emissão > 400 milhões de dólares. A proporção de oferta pública deve ser ≥10%.',
    BEIJIN: '※ Padrão um:<br/>Capitalização de mercado ≥ 200 milhões de RMB;<br/>Lucro líquido não inferior a 15 milhões de dólares nos dois anos mais recentes e retorno líquido médio ponderado não inferior a 8%, ou lucro líquido no ano mais recente não inferior a 25 milhões de dólares e retorno líquido médio ponderado não inferior a 8%.<br/>※ Padrão dois:<br/>Capitalização de mercado ≥ 400 milhões de yuan;<br/>A receita operacional média dos últimos dois anos não deve ser inferior a 100 milhões de yuans, e a taxa de crescimento da receita operacional no ano mais recente não deve ser inferior a 30%. O fluxo de caixa líquido gerado pelas atividades operacionais no ano mais recente é positivo.<br/>※ Padrão três:<br/>Capitalização de mercado ≥ 800 milhões de RMB;<br/>A receita operacional no ano mais recente não deve ser inferior a 200 milhões de yuans. O investimento combinado em pesquisa e desenvolvimento dos últimos dois anos não deve ser inferior a 8% da receita operacional total dos últimos dois anos.<br/>※ Padrão quatro:<br/>Capitalização de mercado ≥1, 5 bilhões de dólares;<br/>O investimento total em p&d nos últimos dois anos não deve ser inferior a 50 milhões de yuans.'
  },
  // 证券资产化
  security: {
    TITLE: 'Securitização de ativos',
    center: 'A securitização de ativos é o processo de emissão de títulos suportados por ativos, através de um desenho estruturado, com base no aumento do nível de crédito, suportado pelos fluxos de caixa gerados no futuro dos ativos subjacentes.',
    entity: 'Ativos físicos',
    entitycenter: 'imóveis, máquinas e equipamentos, etc.',
    number: 'Ativos digitais',
    numbercenter: 'dados, sistemas e muito mais',
    intellectual: 'Ativos ip',
    intellectualcenter: 'patentes de invenção, patentes de aparência, etc.',
    property: 'Ativos financeiros',
    propertycenter: 'Contas a receber'
  },
  // Copyright
  copyright: {
    CULTURE: 'Melt、 financiamento、 fusão sonho inovação、empreendedorismo、criar o futuro',
    CONTACT_INFORMATION: 'Contato',
    ADDRESS_TEXT: 'Endereço postal',
    EMAIL: 'Caixa de correio',
    TEL: 'Telefone',
    ADDRESS_DETAIL: '1903 edifício qianhai world trade, nanshan, shenzhen, china (estação do parque da estrada de ferro, saída D) '
  },
  // 上市前孵化服务
  pattern: {
    title: 'Modelos de negócios e serviços de plano de negócios',
    pattrrm1: 'Coaching com BP',
    pattrrm1center: 'O serviço personalizado BP inclui consultoria one-to-one, posicionamento do projeto, desde a análise de pesquisa da indústria, extração de destaques de investimento, lógica de negócios, análise de modelo de lucro, recomendações de avaliação, planejamento de desenvolvimento, orquestração de design, até dar aos empresários algumas notas sobre como se comunicar com os investidores de forma eficiente e outros serviços de conteúdo.',
    pattrrm2: 'Design de modelo de negócio',
    pattrrm1center2: 'Da estratégia à tática, do fluxo de caixa à lucratividade, das vendas ao marketing, fornecendo planejamento operacional para empresas de latitudes mais altas.',
    pattrrm3: 'Proposta de negócios',
    pattrrm1center3: 'Ajudar as empresas a elaborar planos de negócios profissionais, permitindo uma melhor comunicação entre as empresas e os investidores e permitindo que as empresas obtenham financiamento rapidamente',
    pattrrm4: 'Roadshow',
    pattrrm1center4: 'Treinar os palestrantes do plano de negócios em suas habilidades de lógica de apresentação e respostas de comunicação presencial, permitindo que os palestrantes impressionem rapidamente potenciais investidores.'
  },
  // 合规管理
  management: {
    title: 'Gestão de conformidade',
    text1: 'Negociação no mercado',
    text2: 'Segurança e proteção ambiental',
    text3: 'Qualidade dos produtos',
    text4: 'Mão-de-obra',
    text5: 'Finanças e impostos',
    text6: 'Propriedade intelectual',
    text7: 'Parceiros de negócios'
  },
  // 合规管理
  brand: {
    title: 'Incubação da marca',
    text1: 'Construção de matriz de mídia',
    text2: 'Inquérito de marketing de marca',
    text3: 'Construção boca a boca da marca',
    text4: 'Planejamento de marketing e promoção',
    text5: 'Execução de eventos/reuniões'
  },
  Brandandsales: {
    TITLE: 'Combinação de produto e vendas',
    center: 'A Casfi fornece às empresas serviços de operação integrada de produtos e vendas, incluindo construção de estrutura de marca, operação de novas mídias, incubação de âncora de marca, gerenciamento de plataforma, etc. para listagem.'
  },
  camp: {
    TITLE: 'Campo de Treinamento de Incubação de Novas Mídias',
    center: 'O Campo de Incubação de Operações de Novas Mídias da Casfi incuba principalmente âncoras de celebridades da Internet e operadores de vídeos curtos.O curso se concentra na classificação da estrutura de conteúdo e no posicionamento da personalidade/marca. Ajude as empresas e empreendedores individuais a criar IP exclusivo por meio de novas mídias para injetar vitalidade em suas carreiras; ajudar as empresas a encontrar direções de marca adequadas, criar influenciadores de marca, expandir o reconhecimento da marca e fortalecer as vendas.'
  },
  newcenter: {
    menu1: 'inteir',
    menu2: 'informação industrial',
    menu3: 'dinâmica da empresa',
    menu4: 'dinâmica do projecto',
    viewdetails: 'Ver mais detalhes',
    return: 'Voltar à lista'
  },
  sponsorList: {
    url1: require('../assets/en/union_1pt.png'),
    url_mob1: require('../assets/union_mob_1pt.png'),
    dialog_url1: require('../assets/avatar1.1.png'),
    title1: 'Simon Lam',
    introduction1: ['Spridzands  law firm listador, agente, consultor de grupo', 'Presidente da china, bolsa nasdaq (norte da europa), patrocinador de mercados de alto crescimento'],
    text1: 'Especialista em direito financeiro transfronteiriço de bolsas de valores de vários países, graduou-se cedo na universidade de administração hoteleira RITZ cesariz na suíça. Depois de se formar, juntou-se ao estabelecido JARDINE group em hong kong graduou-se no curso de "gestão administrativa". Mudou-se para o reino unido tornou-se membro sênior do instituto britânico de diretores IoD (1998) e membro do grupo 48 no reino unido. Em 2004, completou um curso de pós-graduação em direito chinês na universidade de shenzhen, na china. Ele trabalhou como ALLIED DUNBAR PLC, uma empresa listada na bolsa de valores de londres "high-end". Agora, ele é o chefe do departamento de fusões e aquisições listadas na europa do escritório de advocacia china harmony, com mais de 50 casos de sucesso de coaching listado.',
    url2: require('../assets/en/union_2pt.png'),
    url_mob2: require('../assets/union_mob_2pt.png'),
    dialog_url2: require('../assets/avatar2.1.png'),
    title2: 'David Heron',
    introduction2: ['Patrocinador da nasdaq first north city e presidente', 'honorário da Spridzands (hong kong) limited'],
    text2: 'David Heron tem 35 anos de experiência em serviços financeiros nas áreas de gestão, vendas e distribuição, Ex-sócio fundador E diretor executivo da AM Capital em hong kong; presidente da Info Point International; gerente geral da E*Trade Securities nas filipinas; vice-presidente da Sapphire Securities; Prime East Diretor associado de Securities e diretor geral da grosvenor executive financial management limited, hong kong. Familiarizado com transações de private equity, gestão de ativos e finanças corporativas, investimentos em ações do mercado secundário, investimentos em IPO, investimentos pré-ipo, etc.',
    url3: require('../assets/en/union_3pt.png'),
    url_mob3: require('../assets/union_mob_3pt.png'),
    dialog_url3: require('../assets/avatar3.1.png'),
    title3: 'Ansis Spridzans',
    introduction3: ['Sócio-diretor do CDO advogados', 'Sócio fundador da Spridzands law firm'],
    text3: 'Ansis Spridzans tem mais de 17 anos de experiência na área jurídica, tendo realizado operações de fusões e aquisições em vários bancos e mercados financeiros, mercados de capitais e desempenhado um papel importante em muitas operações de fusões e aquisições de referência no mercado letão. Em particular, tem um profundo conhecimento dos aspectos legais dos mercados financeiros, de investimento e de capitais na europa oriental e na região pós-soviética. Enquanto presidente da agência de privatização, ansis splijens adquiriu uma vasta experiência em processos administrativos, governamentais e políticos.',
    url4: require('../assets/en/union_4pt.png'),
    url_mob4: require('../assets/union_mob_4pt.png'),
    dialog_url4: require('../assets/avatar4.1.png'),
    title4: 'Mei Xiao bin',
    introduction4: ['Confucionista contemporâneo', 'O investidor anjo', 'Fundador do grupo hehe international'],
    text4: 'Profundamente alfabetizado e religioso budista, trabalhou como gerente geral de várias empresas, participou de operações de listagem de grandes empresas públicas, está familiarizado com o design da estrutura de patrimônio e tem uma rica experiência em gestão de negócios.',
    url5: require('../assets/en/union_5pt.png'),
    url_mob5: require('../assets/union_mob_5pt.png'),
    dialog_url5: require('../assets/avatar5.1.png'),
    title5: 'Jason Jia',
    introduction5: ['Especialistas em serviços governamentais e empresariais'],
    text5: 'Jason é o ministério nacional treinador empresarial, das pequenas e médias empresas de tecnologia micro especialistas em serviços de inovação empresarial, província de guangdong incubadora empresarial de associação mentor, caminho comum fundos públicos promotores das formigas, formigas precursores de fato co-fundador, CST e gerente geral, com mais de 10 anos de experiência de serviços entre o governo, empresas chinesas de centenas de fornecer orientação e aconselhamento de público empresarial.',
    url6: require('../assets/en/union_6pt.png'),
    url_mob6: require('../assets/union_mob_6pt.png'),
    dialog_url6: require('../assets/avatar6.1.png'),
    title6: 'Evan Yu',
    introduction6: ['Especialista em incubação de marcas'],
    text6: 'Co-fundador da zhongke rongchuang, mentor de empreendedorismo da academia chinesa de ciências wuhan, empreendedor múltiplo na internet, especialista em planejamento de modelo de negócios, especialista em novas operações de mídia, gerente de produto sênior, tem uma compreensão única e experiência de uso de tendências da indústria, tendências de consumo e tendências nacionais.',
    url7: require('../assets/en/union_7pt.png'),
    url_mob7: require('../assets/union_mob_7pt.png'),
    dialog_url7: require('../assets/avatar7.1.png'),
    title7: 'Jacky Wong',
    introduction7: ['Especialistas em sistemas de organização empresarial, incentivos de capital, incubação de capital'],
    text7: 'Fundador do MBA china win-win-win system, que atende mais de 200 pequenas e médias empresas. Ele se concentra em fornecer serviços moduares para empresas, como sistemas organizacionais, incentivos de capital, financiamento de crowdfunding e ameba. Ele tem excelentes habilidades de coaching especializado nas áreas de normalização empresarial, multiplicação de desempenho de marketing e equidade de títulos, capacitando as empresas a entrar rapidamente no mercado de capitais.',
    url8: require('../assets/en/union_8pt.png'),
    url_mob8: require('../assets/union_mob_8pt.png'),
    dialog_url8: require('../assets/avatar8.1.png'),
    title8: 'Yan Min',
    introduction8: ['Sócio da deheng advogados'],
    text8: ''
  },
  imgList: {
    img1: require('../assets/home_banner.png'),
    img2: require('../assets/banner-ronzienenpt.png'),
    img3: require('../assets/banner-fuhuapt.png'),
    img4: require('../assets/banner-news-centerpt.png')
  },
  abroadbadyimg: {
    stepone: 'Passo 3',
    steptwo: 'Passo 2',
    stepthree: 'Primeiro passo',
    img: require('../assets/service_way_img_2apt.png'),
    step1text: 'mudar de ações dos eua para listagem na placa-mãe da bolsa de hong kong',
    step2text: 'IPO no nasdaq (eua) ou na bolsa de londres',
    step3text: 'listagem na nasdaq (norte da europa)-CSD e, em seguida, na nasdaq (norte da europa)- first north city IPO para preparar o caminho para a futura listagem'
  },
  ideaurl: {
    img1: require('../assets/home_culture_linian_mobenpt.png'),
    img2: require('../assets/home_culture_value_mobpt.png'),
    img3: require('../assets/home_culture_forward_mobpt.png')
  },
  // bp辅导
  bpCoach: {
    // 页面导航
    breadcrumb: {
      toTitle: 'Serviços de incubação pré-mercado',
      selfTitle: 'Coaching com BP'
    },
    // 标题
    contentTitle: {
      solution: 'BP custom solutions',
      server: 'Garantia de Serviço·Entendemos melhor os investidores',
      custom: 'Processo de atendimento personalizado BP',
      case: 'Caso de serviço personalizado BP',
      organ: 'Instituições de investimento cooperativo'
    },
    // 计划书
    tip: {
      pcUrl: require('../assets/bp/bp_tip_pt.png'),
      h5Url: require('../assets/bp/bp_tip_web_pt.png')
    },
    // BP定制解决方案
    solution: [
      {
        url: require('../assets/bp/bp_icon_data.png'),
        title: 'Dados',
        text: 'Contando com o banco de dados de mercado primário e secundário omnicanal e multidimensional, ele ajuda os clientes a explorar o enorme valor comercial oculto por trás dos dados em seus campos relacionados ao projeto.'
      },
      {
        url: require('../assets/bp/bp_icon_study.png'),
        title: 'Pesquisa',
        text: 'Based on the industry experience of senior analysts,form a full range of market analysis, industry analysis, directional enterprise analysis research results.'
      },
      {
        url: require('../assets/bp/bp_icon_consult.png'),
        title: 'Consultar',
        text: 'Os analistas seniores acompanham todo o processo, realizam pesquisas de produtos competitivos, formulam expectativas razoáveis de desenvolvimento futuro e fazem sugestões razoáveis ​​de intervalo de avaliação durante o período de serviço.'
      },
      {
        url: require('../assets/bp/bp_icon_report.png'),
        title: 'Relatório de pressão arterial',
        text: 'Combinado com os dados completos de investimento e financiamento e o acúmulo de analistas de longo prazo, apresenta o desenvolvimento da empresa a partir dos aspectos de avaliação corporativa, classificação da lógica de negócios, análise do modelo de lucro, planejamento da estratégia de desenvolvimento, informações financeiras etc., e personaliza o plano de negócios que os investidores mais desejam.'
      }
    ],
    server: {
      pcUrl: [
        { url: require('../assets/bp/bp_service_1_pt.png') },
        { url: require('../assets/bp/bp_service_2_pt.png') },
        { url: require('../assets/bp/bp_service_3_pt.png') }
      ],
      h5Url: [
        { url: require('../assets/bp/bp_service_web_1_pt.png') },
        { url: require('../assets/bp/bp_service_web_2_pt.png') },
        { url: require('../assets/bp/bp_service_web_3_pt.png') }
      ]
    },
    // BP定制服务流程
    custom: {
      pcUrl: require('../assets/bp/bp_custom_pt.png'),
      h5Url: [
        {
          url: require('../assets/bp/bp_custom_web_1.png'),
          title: 'Envie uma solicitação',
          text: 'Triagem preliminar do projeto, correspondên-cia de analistas e com-unicação de demanda.'
        },
        {
          url: require('../assets/bp/bp_custom_web_2.png'),
          title: 'Consultoria e negociaç',
          text: 'Faça pré-entrevista, pes-quisa e análise, forneça soluções personalizadas e faça cotações razoáveis ​​de acordo com as neces-sidades do usuário e os custos do serviço'
        },
        {
          url: require('../assets/bp/bp_custom_web_3.png'),
          title: 'assinatura do contrato',
          text: 'Assinatura do contrato: Assine um contrato formal para garantir a qualidade do serviço, proteger os direitos e interesses do cliente e emitir faturas após o pagamento.'
        },
        {
          url: require('../assets/bp/bp_custom_web_4.png'),
          title: 'Entrevista com analista',
          text: 'Entrevistas 1V1 em pro-fundidade com analistas seniores e atendimento customizado de acordo com o estágio do projeto.'
        },
        {
          url: require('../assets/bp/bp_custom_web_5.png'),
          title: 'BP personalizado',
          text: 'Posicionamento do projeto, classificação lógica de neg-ócios, análise de modelo de lucro, extração de destaque de investimento, planejamento de desenvolvimento, sugestões de avaliação, etc.'
        }
      ]
    },
    // BP定制服务案例图片
    case: {
      pcUrl: require('../assets/bp/bp_case_pt.png'),
      h5Url: [
        { url: require('../assets/bp/bp_case_web_1_pt.png') },
        { url: require('../assets/bp/bp_case_web_2_pt.png') },
        { url: require('../assets/bp/bp_case_web_3_pt.png') },
        { url: require('../assets/bp/bp_case_web_4_pt.png') },
        { url: require('../assets/bp/bp_case_web_5_pt.png') },
        { url: require('../assets/bp/bp_case_web_6_pt.png') }
      ]
    },
    // 合作投资机构
    organ: {
      pcUrl: require('../assets/bp/bp_organ.png'),
      h5Url: require('../assets/bp/bp_organ_web.png')
    }
  },
  // 上市孵化服务新增版块
  incubation: {
    title: 'Capital social da empresa pago',
    capitalList: [
      {
        title: 'Subscrito VS realizado',
        text: 'A partir de 1 º de março de 2014, foi implementada a mudança do sistema de registro de capital realizado para o sistema de registro subscrito e a flexibilização de outras condições para o registro de empresas e empresas. Este capital registrado da empresa é escrito livremente, para mostrar a força da empresa. Mas isso também coloca riscos para os acionistas de capital. O artigo 3 º da lei das sociedades estabelece que "os acionistas de uma sociedade limitada são responsáveis pela sociedade até ao montante do capital subscrito; Os accionistas de uma sociedade anónima são responsáveis pela sociedade até ao limite das acções que subscreveram ". Uma vez que o risco de dívida no processo de operação da empresa, os acionistas são subscritos não pagos, precisa de acordo com o montante subscrito é limitado a responsabilizar os acionistas.',
        pcUrl: require('@/assets/incubation/capital_pc_1.png'),
        h5Url: require('@/assets/incubation/capital_h5_1.png'),
        isOpen: false
      },
      {
        title: 'Modalidades de pagamento',
        text: 'O artigo 27 da lei das sociedades mercantis estabelece que: os acionistas podem fazer contribuições monetárias ou em bens não monetários, como bens em espécie, propriedade intelectual, posse de terras, etc., que podem ser avaliados em moeda e podem ser legalmente transferidos; Portanto, quando o acionista cumpriu a obrigação de pagamento da empresa em que está localizado, é natural e o risco da operação da empresa alienada. O artigo 3 º da lei das sociedades estabelece que "a sociedade é responsável pelas dívidas da sociedade com todos os seus bens". Os acionistas não são obrigados a assumir responsabilidades adicionais após o cumprimento da obrigação de pagamento.',
        pcUrl: require('@/assets/incubation/capital_pc_2.png'),
        h5Url: require('@/assets/incubation/capital_h5_2.png'),
        isOpen: false
      }
    ],
    childTitle: 'Processo de operação',
    capitalData: [
      {
        url: require('@/assets/bp/bp_custom_web_1.png'),
        title: 'Assinar um contrato',
        text: 'Assinatura do contrato e fornecimento de materiais para aumento de capital.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_2.png'),
        title: 'Materiais de acabamento',
        text: 'As empresas associadas fazem a triagem relacionada dos materiais, extraindo e aca-bando, avaliando o trabalho.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_3.png'),
        title: 'Value evaluation',
        text: 'Avaliação completa emissão de relatório de avaliação e relatório de inspeção de capital, confirmado e assinado por especialistas de alto nível na indústria.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_4.png'),
        title: 'Industrial and commercial audit',
        text: 'O comissário da indústria e do comércio irá à sua empresa com o relatório para a assinatura dos acionistas, e à indústria e ao comércio locais para a auditoria, a primeira avaliação e a segunda aprovação, completam o processamento industrial e comercial.'
      },
      {
        url: require('@/assets/bp/bp_custom_web_5.png'),
        title: 'Successful paid-in/ capital increase',
        text: 'Realização de pagamentos/aumentos de capital.'
      }
    ]
  }
}
