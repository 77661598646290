import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker' // 页面缓存
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import './mock/mock.js'
import VueI18n from 'vue-i18n'
import hevueImgPreview from 'hevue-img-preview'
import './plugins/VueQuillEditor.js'
Vue.use(hevueImgPreview)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.state.langSelect, // 语言标识
  fallbackLocale: 'zh-CN',
  messages: {
    'zh-CN': require('./lang/zh'), // 中文语言包
    'en-US': require('./lang/en'), // 英文语言包
    'pt-PT': require('./lang/pt') // 葡萄牙语言包
  }
})
// 全局注册axios
Vue.prototype.$axios = axios
axios.defaults.baseURL = 'https://service.ddgeyou.com/qifu/'
axios.defaults.timeout = 3000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
