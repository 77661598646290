import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news_center',
    name: 'NewsCenter',
    component: () => import('../views/NewsCenter.vue')
  },
  {
    path: '/incubation_service',
    name: 'IncubationService',
    component: () => import('../views/IncubationService.vue')
  },
  {
    path: '/financing_service',
    name: 'FinancingService',
    component: () => import('../views/FinancingService.vue')
  },
  {
    path: '/newscenter',
    name: 'NewsCenter',
    component: () => import('../components/News/NewsCenter.vue')
  },
  {
    path: '/newschild',
    name: 'NewsChild',
    component: () => import('../views/Newschild.vue')
  },
  {
    path: '/bpcoach',
    name: 'Bpcoach',
    component: () => import('../views/Bpcoach.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: '/',
        name: 'dashboard',
        redirect: '/news',
        component: () => import('@/admin/Dashboard.vue'),
        meta: ['新闻管理', '新闻资讯'],
        children: [
          {
            path: '/news',
            name: 'news',
            component: () => import('@/admin/News.vue'),
            meta: ['新闻管理', '新闻资讯']
          },
          {
            path: '/newsdetail',
            name: 'newsdetail',
            component: () => import('@/admin/NewsDetail.vue'),
            meta: ['新闻管理', '新闻资讯', '新闻详情']
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  // window.pageYOffset = 0
  next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
